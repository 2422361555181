import registerCredentialTemplate from "./registerCredentialTemplate";

const registerInfoTemplate = `
<div class="alert alert-danger" data-ng-if="errors.length > 0">
    <p data-ng-repeat="error in errors">{{error}}</p>
</div>

<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
    <header class="contained-form-header">
        <h1> {{"usernamePasswordTitle"| translate}}</h1>
    </header>

    <div class="inner-container with-padding with-shadow">
        <form role="form" id="registration-form" name="accountSetupForm" data-ng-submit="registeUser()" novalidate
            autocomplete="off">

            <div class="form-group has-error" aria-live="assertive">
                <span class="help-block" ng-bind-html="saveStatus | translate:saveStatusParams" role="alert"></span>
            </div>

            ${registerCredentialTemplate}

            <div class="form-group clearfix">
                <button type="submit" id="submit" class="btn btn-primary btn-block btn-lg margin-top-default"
                    ng-disabled="showSpinner==true">
                    <span data-ng-hide="showSpinner==true">
                        Register
                    </span>

                    <span data-ng-show="showSpinner==true">
                        <i class="fa fa-circle-o-notch fa-spin"></i>
                        Processing ...
                    </span>
                </button>

            </div>

        </form>
    </div>
</div>

`;
export default registerInfoTemplate;
