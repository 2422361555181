import type { AxiosResponse } from "axios";
import ReferenceData from "../../../constants/ReferenceData";
import { executeGet } from "../../../utils/appUtils";

interface GetBeneficiariesResponse {
    beneficiaries: {
        beneTypeCode: string;
        designationCode: "P" | "C";
        firstName: string;
        id: string;
        lastName: string;
        percent: number;
    }[];
    beneficiaryTypes: { code: string; description: string }[];
}

const getBeneficiaries = (gaId: string): Promise<AxiosResponse<GetBeneficiariesResponse>> => {
    const getBeneficiariesSuccess = (results) => {
        if (results) {
            return results;
        }
    };

    const liveUrl = ReferenceData.GET_BENEFICIARIES + "/" + gaId;

    return executeGet(liveUrl, "getBeneficiariesFault")
        .then(getBeneficiariesSuccess)
        .catch((err) => {
            console.log("getBeneficiaries Error: ", err);
            throw err;
        });
};

export default getBeneficiaries;
