import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { REDWOOD_MFA, REDWOOD_MFA_PHONE, REDWOOD_MFA_SMS } from "../../routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DEFAULT_STALE_TIME } from "../../queries/constants";
import { TranslationsServiceProvider } from "../../providers/TranslationsServiceProvider";
import LandingRedwoodMfa from "./LandingRedwoodMfa";
import LandingSmsRedwoodMfa from "./LandingSmsRedwoodMfa";
import LandingPhoneRedwoodMfa from "./LandingRedwoodPhoneMfa";

//TODO:This wrapper will remove once angular components completely migrated to react

const LandingRedwoodMfaWrapper = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: DEFAULT_STALE_TIME
            }
        }
    });
    return (
        <QueryClientProvider client={queryClient}>
            <TranslationsServiceProvider>
                <HashRouter>
                    <Routes>
                        <Route path={REDWOOD_MFA} element={<LandingRedwoodMfa />} />
                        <Route path={REDWOOD_MFA_SMS} element={<LandingSmsRedwoodMfa />} />
                        <Route path={REDWOOD_MFA_PHONE} element={<LandingPhoneRedwoodMfa />} />
                    </Routes>
                </HashRouter>
            </TranslationsServiceProvider>
        </QueryClientProvider>
    );
};

export default LandingRedwoodMfaWrapper;
