import ReferenceData from "../../constants/ReferenceData";
import { executeGet, executePost } from "../../utils/appUtils.js";

const handleSuccess = (res) => {
    if (res && res.data) {
        return res.data;
    }
};

const handleError = (err, service) => {
    console.log(`${service} Error: ${err.data}`);
    throw err;
};

export const validatePPTDataWithNPDI = (params) => {
    const url = __authenticationUrl__ + ReferenceData.NPDI.VALIDATE_PPT_DATA;

    return executePost(url, "validatePPTDataWithNPDIFault", params)
        .then(handleSuccess)
        .catch((err) => handleError(err, "validatePPTDataWithNPDIFault"));
};

export const retrieveDropdownValuesOnNpdiPageLoad = () => {
    const url = __authenticationUrl__ + ReferenceData.NPDI.RETRIEVE_DROPDOWN_VALUES;

    return executeGet(url)
        .then(handleSuccess)
        .catch((err) => handleError(err, "retrieveDropdownValuesOnNpdiPageLoad"));
};

export const addNPDIppt = (params) => {
    const url = __authenticationUrl__ + ReferenceData.NPDI.ADD_NPDI_PPT;

    return executePost(url, "addNPDIpptFault", params)
        .then(handleSuccess)
        .catch((err) => handleError(err, "addNPDIpptFault"));
};

export const retrievePlansToLink = () => {
    const url = __authenticationUrl__ + ReferenceData.NPDI.RETRIEVE_PLANS;

    return executeGet(url)
        .then(handleSuccess)
        .catch((err) => handleError(err, "retrievePlansToLink"));
};

export const isPECKTMGEnabled = () => {};

export const updNPDIPPTEnroll = () => {
    const url = __authenticationUrl__ + ReferenceData.NPDI.UPD_NPDI_ENROLL;

    return executePost(url).then(handleSuccess, (err) => err);
};
