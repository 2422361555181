import ReferenceData from "../../constants/ReferenceData.js";
import { executeGet, executePost } from "../../utils/appUtils.js";

const handleSuccess = (res) => {
    if (res && res.data) {
        return res.data;
    }
};

const handleError = (err, service) => {
    console.log(`${service} Error: ${err.data}`);
    throw err;
};

export const retrieveContactDetails = (isNpdiFlow) => {
    const endpoint = isNpdiFlow
        ? ReferenceData.NPDI.RETRIEVE_CONTACT_DETAILS
        : ReferenceData.ACCOUNT_SETUP.RETRIEVE_CONTACT_DETAILS;
    const url = __authenticationUrl__ + endpoint;

    return executeGet(url)
        .then(handleSuccess)
        .catch((err) => handleError(err, "retrieveContactDetails"));
};

export const updateContact = (params) => {
    const url = __authenticationUrl__ + ReferenceData.ACCOUNT_SETUP.UPDATE_CONTACT;

    return executePost(url, "updateContact", params)
        .then(handleSuccess)
        .catch((err) => handleError(err, "updateContact"));
};

export const registerUser = (params) => {
    const url = __authenticationUrl__ + ReferenceData.ACCOUNT_SETUP.REGISTER_USER;

    return executePost(url, "registerUser", params)
        .then(handleSuccess)
        .catch((err) => handleError(err, "registerUser"));
};
