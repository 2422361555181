import StringUtil from "core-ui/client/src/app/core/util/StringUtil";
import ReferenceData from "../constants/ReferenceData";
import { executeGet } from "../utils/appUtils";

const handleSuccess = (res) => {
    if (res && res.data) {
        return res;
    }
};

const handleError = (err, service) => {
    console.log(`${service} Error: ${err.data}`);
    throw err;
};

// specific plan txn Access
export const getGroupTxnAccess = (params) => {
    const payload = {
        txnCode: params.txnCode,
        individualId: params.individualId,
        groupId: params.groupId
    };

    const url = ReferenceData.GROUP_TXN_ACCESS;

    const liveUrl = __baseApiUrl__ + StringUtil.supplant(url, payload);

    return executeGet(liveUrl, "getGroupTxnAccessFault")
        .then(handleSuccess)
        .catch((err) => handleError(err, "getGroupTxnAccess"));
};
