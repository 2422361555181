import React, { MouseEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "../../../queries";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import eventBus from "../../../../utils/setEventBus";
import { LOGIN_HELP_OPTIONS } from "../../../routes";
interface LoginHelpNoRecordProps {
    accu?: string;
    location?: { path: (path: string) => void };
    scope?: { $apply: () => void; $root: { featureName: string } };
}

interface LoginHelpNoRecordTranslations {
    loginHelpFailure: {
        noRecord: string;
        noRecordFoundDesc: string;
        signUpBtn: string;
        tryAgainBtn: string;
    };
}
const LoginHelpNoRecord = ({ location, scope }: LoginHelpNoRecordProps) => {
    const { loginHelpFailure } = useTranslations<LoginHelpNoRecordTranslations>();
    const navigate = useNavigate();
    const tryAgainBtn = loginHelpFailure.tryAgainBtn;
    const tryAgainBtnId = String(tryAgainBtn).trim().toLowerCase().replace(/\s/g, "_");
    const signUpBtn = loginHelpFailure.signUpBtn;
    const signUpBtnId = String(signUpBtn).trim().toLowerCase().replace(/\s/g, "_");
    const buttonRef = React.useRef<HTMLButtonElement | null>(null);

    React.useEffect(() => {
        if (buttonRef.current) {
            buttonRef.current.focus();
        }
    }, []);

    const updateAngularRoutingState = useCallback(
        (destination) => {
            /**
             * TODO: Delete this function when Angular is removed. Since we are using the React router
             * we need to update the Angular routing state to match the new React routing state.
             */
            if (location && scope) {
                location.path(destination);
                scope.$apply();
            }
        },
        [location, scope]
    );

    const handleSignUp = (event: MouseEvent) => {
        event.preventDefault();
        const payload = String(event.currentTarget.id).toLowerCase();
        eventBus.dispatch(AMPLITUDE_EVENTS.SELECT_BUTTON, event.target, payload);
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: payload,
            payload: {
                payload
            }
        });

        let destUrl = "https://empower.com/signup";
        if (
            String(window.location.href).indexOf("localhost") > -1 ||
            String(window.location.href).indexOf("proj10") > -1
        ) {
            destUrl = "https://empower.com/signup-v1";
        }

        window.location.href = destUrl;
    };
    /**
     *
     * @param event
     */
    const handleTryAgain = (event: MouseEvent) => {
        event.preventDefault();
        const payload = String(event.currentTarget.id).toLowerCase();
        eventBus.dispatch(AMPLITUDE_EVENTS.SELECT_BUTTON, event.target, payload);

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: tryAgainBtnId,
            payload: {
                payload
            }
        });

        navigate("/" + LOGIN_HELP_OPTIONS, {
            replace: true,
            state: {
                type: "noRecord",
                failedAffiliate: "",
                failedFreemium: ""
            }
        });
        updateAngularRoutingState(LOGIN_HELP_OPTIONS);
    };

    return (
        <div className="loginhelp-container" data-testid="login-help-no-record-found">
            <h1>{loginHelpFailure.noRecord}</h1>
            <div className="description mb-50">{loginHelpFailure.noRecordFoundDesc}</div>
            <div className="button-container">
                <button
                    id={signUpBtnId}
                    className="btn btn-primary mb-25"
                    type="submit"
                    aria-label={signUpBtn}
                    data-selection={signUpBtnId}
                    onClick={handleSignUp}
                    ref={buttonRef}
                >
                    {loginHelpFailure.signUpBtn}
                </button>
                <button
                    id={tryAgainBtnId}
                    className="btn"
                    type="submit"
                    aria-label={tryAgainBtn}
                    data-selection={tryAgainBtnId}
                    onClick={handleTryAgain}
                >
                    {loginHelpFailure.tryAgainBtn}
                </button>
            </div>
        </div>
    );
};

export default LoginHelpNoRecord;
