import { skipToken, useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import getBeneficiaries from "../../services/common/getBeneficiaries";

const useGetBeneficiaries = (gaId: string | undefined) => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_BENEFICIARIES, gaId],
        queryFn: gaId
            ? async () => {
                  const { data } = await getBeneficiaries(gaId);
                  return data;
              }
            : skipToken,
        enabled: Boolean(String(gaId) > "")
    });
};

export default useGetBeneficiaries;
