/**
 *
 * @name app.accountSetup.events.register
 * @copyright 2025 Empower Retirement
 * @description
 *
 *  These are the events for register views
 */

const root = "register.";

const RegisterEvents = {
    PGE_VIEW_CREATE_USERNAME_PASSWORD: root + "create_username_password_view",
    SUCCESSFUL_SUBMIT_CREATE_USERNAME_PASSWORD: root + "create_username_password_submit",
    ATTEMPT_SUBMIT_CREATE_USERNAME_PASSWORD: root + "create_username_password_submit_attempt",
    PGE_VIEW_VERIFY_CONTACT_INFO: root + "verify_contact_info_view",
    SUCCESSFUL_SUBMIT_VERIFY_CONTACT_INFO: root + "verify_contact_info_submit",
    ATTEMPT_SUBMIT_VERIFY_CONTACT_INFO: root + "verify_contact_info_submit_attempt"
};

export default RegisterEvents;
