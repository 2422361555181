import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
    retrieveDropdownValuesOnNpdiPageLoad,
    validatePPTDataWithNPDI
} from "../../services/npdi/npdiService";
import { useForm } from "react-hook-form";
import { isBeforeDate, isFutureDate, validDate } from "./validators";
import MaskInput from "react-text-mask";
import typeahead from "typeahead-standalone";
import "typeahead-standalone/dist/basic.css";
import NpdiPayStubInfo from "./NpdiPayStubInfo";
import Registration from "../registration/Registration";
import { find as _find } from "lodash";
import { useTranslations } from "../../queries";
import { formatDate, formatSSN } from "../../utils/formUtils";
import { NpdiRegistrationTranslations } from "./types";
import useRedirect from "../../hooks/useRedirect";
import stateMap from "../../services/redirect/StateMap";

const FLOW_NAME = "npdiAccountSetupFlow";

const NpdiForm = () => {
    const {
        register,
        setValue,
        handleSubmit,
        getValues,
        formState: { errors },
        trigger,
        watch
    } = useForm();
    const { data: npdiData } = useQuery({
        queryKey: ["retrieve-dropdown"],
        queryFn: () => retrieveDropdownValuesOnNpdiPageLoad()
    });
    const [ssnType, setSsnType] = useState("text");
    const [showOtherZipcode, setShowOtherZipcode] = useState(false);
    const [showOtherCityState, setShowOtherCityState] = useState(false);
    const showCTPEC = npdiData?.customCTPEC === "HIDE_STATE_AGENCY_QN";
    const showStateAgency = npdiData?.customCTPEC === "SHOW_STATE_AGENCY_QN";
    const [stateAgencySwitch, setStateAgencySwitch] = useState("null");
    const { npdiForm }: { npdiForm: NpdiRegistrationTranslations } = useTranslations();
    const navigate = useNavigate();
    const redirect = useRedirect();

    const mutation = useMutation({
        mutationFn: validatePPTDataWithNPDI,
        onSuccess: (response) => {
            redirect(response, { flowName: "npdiAccountSetupFlow" });
        },
        onError: () => {
            const npdiErrorUrl = stateMap.get("NPDI_ERROR");
            if (npdiErrorUrl) {
                navigate(npdiErrorUrl);
            }
        }
    });

    const npdiValidatePPTData = (data) => {
        const currentAnnualIncome = Number(data.currentAnnualIncome.replace(/[$,]/g, ""));

        const formatDateForPayload = (date) => date.replace(/\//g, "");

        const formData = {
            ...data,
            flowName: FLOW_NAME,
            currentAnnualIncome,
            dateOfBirth: formatDateForPayload(data.dateOfBirth),
            dateOfHire: formatDateForPayload(data.dateOfHire)
        };

        mutation.mutate(formData);
    };

    useEffect(() => {
        const input = document.getElementById("divisionInput") as HTMLInputElement;

        if (input && npdiData) {
            const options = npdiData?.divisionCodes.map((code) => code.name);

            typeahead({
                input,
                highlight: true,
                source: {
                    local: [...options]
                },
                autoSelect: true,
                preventSubmit: true,
                hint: false,
                templates: {
                    empty: () => [...options]
                },
                limit: options.length
            });

            // explicitly defining the class names on typeahead can break styling
            // however without class names a default 'tt-input' is added to input field which also breaks styling
            // solution: remove 'tt-input' class after typeahead init
            const newInput = document.querySelector(".tt-input");

            if (newInput) {
                newInput.classList.remove("tt-input");
            }
        }
    }, [npdiData]);

    const onSsnBlur = () => {
        setSsnType("password");
    };

    const onSsnFocus = () => {
        setSsnType("text");
    };

    const toSentenceCase = (str) => {
        return str.toLowerCase().replace(/(^[a-z]| [a-z]|-[a-z])/g, function ($1) {
            return $1.toUpperCase();
        });
    };

    const updateAddressVisibility = (e) => {
        const val = e.target.value;

        if (val !== "US") {
            setShowOtherZipcode(true);
            if (val !== "CA") {
                setShowOtherCityState(true);
            }
        } else {
            setShowOtherZipcode(false);
            setShowOtherCityState(false);
        }
    };

    const triggerValidation = (e) => {
        trigger(e.target.name);
    };

    const clearDivisionCode = () => {
        setValue("divisionCodes", "");
    };

    const divisionCodeValue = watch("divisionCodes");

    return (
        <main id="main-body" className="with-breadcrumbs with-top-margin">
            <div className="npdi-container row">
                <form
                    autoComplete="off"
                    name="npdiForm"
                    noValidate
                    onSubmit={handleSubmit(npdiValidatePPTData)}
                >
                    <header className="contained-form-header col-sm-10 col-sm-offset-1 col-xs-12">
                        <h1>{npdiForm.registrationTitle}</h1>
                        <p>{npdiForm.enterNpdiInfo}</p>
                    </header>
                    <div
                        id="content-container"
                        className="col-sm-10 col-sm-offset-1 col-xs-12 inner-container with-padding with-shadow"
                    >
                        <h2 className="margin-top-0">{npdiForm.personalInformationTitle}</h2>
                        <div className="row">
                            <div
                                className={`form-group col-xs-12 col-sm-5 ${
                                    errors.firstName && "has-error"
                                }`}
                            >
                                <label htmlFor="firstNameInput" className="control-label">
                                    {npdiForm.personalInfo?.firstName}
                                </label>
                                <input
                                    {...register("firstName", {
                                        required: npdiForm.firstNameRequired,
                                        maxLength: {
                                            value: 20,
                                            message: npdiForm.firstNameInvalidLength
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z' ]+$/,
                                            message: npdiForm.firstNameSpecialChar
                                        },
                                        onBlur: triggerValidation
                                    })}
                                    id="firstNameInput"
                                    className="form-control"
                                />
                                {errors.firstName && errors.firstName.message instanceof String && (
                                    <p className="error-text">{errors.firstName.message}</p>
                                )}
                            </div>
                            <div
                                className={`form-group col-xs-12 col-sm-2 ${
                                    errors.middleName && "has-error"
                                }`}
                            >
                                <label htmlFor="middleNameInput" className="control-label">
                                    {npdiForm.personalInfo?.middleName}
                                </label>
                                <input
                                    {...register("middleName", {
                                        maxLength: {
                                            value: 20,
                                            message: npdiForm.middleNameInvalidLength
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z' ]+$/,
                                            message: npdiForm.middleNameSpecialChar
                                        }
                                    })}
                                    id="middleNameInput"
                                    className="form-control"
                                />
                                {errors.middleName &&
                                    errors.middleName.message instanceof String && (
                                        <p className="error-text">{errors.middleName.message}</p>
                                    )}
                            </div>
                            <div
                                className={`form-group col-xs-12 col-sm-5 ${
                                    errors.lastName && "has-error"
                                }`}
                            >
                                <label htmlFor="lastNameInput" className="control-label">
                                    {npdiForm.personalInfo?.lastName}
                                </label>
                                <input
                                    {...register("lastName", {
                                        required: npdiForm.lastNameRequired,
                                        maxLength: 35,
                                        pattern: /^[a-zA-Z' ]+$/,
                                        onBlur: triggerValidation
                                    })}
                                    id="lastNameInput"
                                    className="form-control"
                                />
                                {errors.lastName && errors.lastName.message instanceof String && (
                                    <p className="error-text">{errors.lastName.message}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={`form-group col-xs-12 col-sm-6 ${
                                    errors.dateOfBirth && "has-error"
                                }`}
                            >
                                <label htmlFor="dateOfBirth" className="control-label">
                                    {npdiForm.personalInfo?.dateOfBirth}&nbsp;
                                    <span className="label-format" aria-hidden="true">
                                        {npdiForm.personalInfo?.dateFormat}
                                    </span>
                                </label>
                                <input
                                    className="form-control"
                                    {...register("dateOfBirth", {
                                        required: npdiForm.dateOfBirthRequired,
                                        onBlur: (e) => {
                                            triggerValidation(e);
                                        },
                                        validate: {
                                            valid: (v) => validDate(v, false),
                                            future: (v) => isFutureDate(v, false),
                                            before: (v) => isBeforeDate(v)
                                        },
                                        onChange: formatDate
                                    })}
                                />
                                {errors.dateOfBirth &&
                                    errors.dateOfBirth.message instanceof String && (
                                        <p className="error-text">{errors.dateOfBirth.message}</p>
                                    )}
                                {errors.dateOfBirth?.type === "future" && (
                                    <p className="error-text">
                                        {npdiForm.dateOfBirthFutureDateNotAllowed}
                                    </p>
                                )}
                                {errors.dateOfBirth?.type === "before" && (
                                    <p className="error-text">
                                        {npdiForm.dateOfBirthYearOutOfRange}
                                    </p>
                                )}
                                {errors.dateOfBirth?.type === "valid" && (
                                    <p className="error-text">{npdiForm.dateOfBirthInvalid}</p>
                                )}
                            </div>
                            <div
                                className={`form-group col-xs-12 col-sm-6 ${
                                    errors.gender && "has-error"
                                }`}
                            >
                                <label htmlFor="genderInput" className="control-label">
                                    {npdiForm.personalInfo?.gender}
                                </label>
                                <div className="custom-select form-control">
                                    <select
                                        {...register("gender", {
                                            required: npdiForm.genderRequired,
                                            onBlur: triggerValidation
                                        })}
                                        id="genderInput"
                                        className="form-control"
                                    >
                                        <option defaultValue=""></option>
                                        {npdiData?.genderList?.map((gender) => {
                                            return (
                                                <option key={`option_${gender}`}>{gender}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {errors.gender && errors.gender.message instanceof String && (
                                    <p className="error-text">{errors.gender.message}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={`form-group col-xs-12 col-sm-6 ${
                                    errors.ssn && "has-error"
                                }`}
                            >
                                <label htmlFor="ssnInput" className="control-label">
                                    {npdiForm.personalInfo?.ssn}
                                </label>
                                <input
                                    type={ssnType}
                                    className="form-control"
                                    {...register("ssn", {
                                        required: npdiForm.ssnRequired,
                                        onBlur: (e) => {
                                            onSsnBlur();
                                            triggerValidation(e);
                                        },
                                        onChange: formatSSN
                                    })}
                                    onFocus={onSsnFocus}
                                />
                                {errors.ssn && errors.ssn.message instanceof String && (
                                    <p className="error-text">{errors.ssn.message}</p>
                                )}
                            </div>
                            <div
                                className={`form-group col-xs-12 col-sm-6 ${
                                    errors.maritalStatus && "has-error"
                                }`}
                            >
                                <label htmlFor="maritalStatusInput" className="control-label">
                                    {npdiForm.personalInfo?.maritalStatus}
                                </label>
                                <div className="custom-select form-control">
                                    <select
                                        {...register("maritalStatus", {
                                            required: npdiForm.maritalStatusRequired
                                        })}
                                        id="maritalStatusInput"
                                        className="form-control"
                                    >
                                        <option defaultValue=""></option>
                                        {npdiData?.maritalStatusList?.map((status) => {
                                            return (
                                                <option key={`option_${status.description}`}>
                                                    {toSentenceCase(status.description)}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {errors.maritalStatus &&
                                    errors.maritalStatus.message instanceof String && (
                                        <p className="error-text">{errors.maritalStatus.message}</p>
                                    )}
                            </div>
                        </div>
                        <header>
                            <h2>{npdiForm.employmentInformationTitle}</h2>
                        </header>
                        <div className="row">
                            <div
                                className={`form-group col-xs-12 col-sm-6 ${
                                    errors.currentAnnualIncome && "has-error"
                                }`}
                            >
                                <label htmlFor="currentAnnualIncomeInput" className="control-label">
                                    {npdiForm.employmentInfo.currentAnnualIncome}
                                </label>
                                <input
                                    {...register("currentAnnualIncome", {
                                        required: npdiForm.currentAnnualIncomeRequired,
                                        onBlur: triggerValidation,
                                        maxLength: {
                                            value: 13,
                                            message: npdiForm.currentAnnualIncomeInvalidLength
                                        },
                                        onChange: (e) => {
                                            const value = e.target.value.replace(/[^0-9]/g, "");
                                            const formattedValue = new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD"
                                            }).format(value / 100);

                                            e.target.value = formattedValue;
                                        }
                                    })}
                                    type="text"
                                    inputMode="numeric"
                                    id="currentAnnualIncomeInput"
                                    className="form-control"
                                />
                                {errors.currentAnnualIncome &&
                                    errors.currentAnnualIncome.message instanceof String && (
                                        <p className="error-text">
                                            {errors.currentAnnualIncome.message}
                                        </p>
                                    )}
                            </div>
                            <div
                                className={`form-group col-xs-12 col-sm-6 ${
                                    errors.dateOfHire && "has-error"
                                }`}
                            >
                                <label htmlFor="dateOfHire" className="control-label">
                                    {npdiForm.employmentInfo.dateOfHire}&nbsp;
                                    <span className="label-format" aria-hidden="true">
                                        {npdiForm.personalInfo.dateFormat}
                                    </span>
                                </label>
                                <input
                                    className="form-control"
                                    title="Format two digit month, two digit day and four digit year"
                                    {...register("dateOfHire", {
                                        required: npdiForm.dateOfHireRequired,
                                        onBlur: triggerValidation,
                                        validate: {
                                            valid: (v) => validDate(v, false),
                                            future: (v) => isFutureDate(v, false),
                                            before: (v) => isBeforeDate(v)
                                        },
                                        onChange: formatDate
                                    })}
                                />
                                {errors.dateOfHire &&
                                    errors.dateOfHire.message instanceof String && (
                                        <p className="error-text">{errors.dateOfHire.message}</p>
                                    )}
                                {errors.dateOfHire?.type === "future" && (
                                    <p className="error-text">
                                        {npdiForm.dateOfHireFutureDateNotAllowed}
                                    </p>
                                )}
                                {errors.dateOfHire?.type === "before" && (
                                    <p className="error-text">
                                        {npdiForm.dateOfHireYearOutOfRange}
                                    </p>
                                )}
                                {errors.dateOfHire?.type === "valid" && (
                                    <p className="error-text">{npdiForm.dateOfHireInvalid}</p>
                                )}
                            </div>
                        </div>

                        {npdiData?.divisionCodes?.length > 1 && !(showCTPEC || showStateAgency) && (
                            <div className="row">
                                <div
                                    className={`form-group col-xs-12 col-sm-6 ${
                                        errors.divisionCode && "has-error"
                                    }`}
                                >
                                    <label htmlFor="divisionInput" className="control-label">
                                        {npdiForm.employmentInfo.divisionCode}
                                    </label>
                                    <span className="sr-only" id="division-input">
                                        Cancel {npdiForm.employmentInfo.divisionCode}
                                    </span>
                                    <input
                                        {...register("divisionCodes", {
                                            required: npdiForm.divisionCodeRequired,
                                            onBlur: triggerValidation,
                                            validate: (val) => {
                                                return Boolean(
                                                    _find(
                                                        npdiData?.divisionCodes,
                                                        (c) => c.name === val
                                                    )
                                                );
                                            }
                                        })}
                                        type="text"
                                        id="divisionInput"
                                        className="form-control bootstrap-selectize-input"
                                    />
                                    {divisionCodeValue && (
                                        <input
                                            type="button"
                                            value="x"
                                            aria-labelledby="division-input"
                                            className="bootstrap-selectize-input-clear-button bootstrap-selectize-input-clear-button-bar-format"
                                            onClick={clearDivisionCode}
                                            id="clear-button"
                                        />
                                    )}

                                    {errors.divisionCodes &&
                                        errors.divisionCodes.message instanceof String && (
                                            <p className="error-text">
                                                {errors.divisionCodes.message}
                                            </p>
                                        )}
                                    {errors.divisionCodes?.type === "validate" && (
                                        <p className="error-text">
                                            {npdiForm.divisionCodeNotFound}
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}
                        <header>
                            <h2> {npdiForm.mailingAddressInformationTitle}</h2>
                        </header>
                        <div className="row">
                            <div
                                className={`form-group col-xs-12 col-sm-6 ${
                                    errors.addressLine1 && "has-error"
                                }`}
                            >
                                <label htmlFor="addressLine1Input" className="control-label">
                                    {npdiForm.mailingAddress.addressLine1}
                                </label>
                                <input
                                    {...register("addressLine1", {
                                        required: npdiForm.addressLine1Required,
                                        maxLength: {
                                            value: 35,
                                            message: npdiForm.addressLine1InvalidLength
                                        },
                                        onBlur: triggerValidation,
                                        pattern: {
                                            value: /^(?=.*\d)[a-zA-Z0-9\-/#&; ]+$/,
                                            message: npdiForm.addressLine1InvalidPattern
                                        }
                                    })}
                                    type="text"
                                    id="addressLine1Input"
                                    className="form-control"
                                />
                                {errors.addressLine1 &&
                                    errors.addressLine1.message instanceof String && (
                                        <p className="error-text">{errors.addressLine1.message}</p>
                                    )}
                            </div>
                            <div
                                className={`form-group col-xs-12 col-sm-6 ${
                                    errors.addressLine2 && "has-error"
                                }`}
                            >
                                <label htmlFor="addressLine2Input" className="control-label">
                                    {npdiForm.mailingAddress.addressLine2}
                                </label>
                                <input
                                    {...register("addressLine2", {
                                        maxLength: {
                                            value: 35,
                                            message: npdiForm.addressLine2InvalidLength
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9-/#&; ]+$/,
                                            message: npdiForm.addressLine2Invalid
                                        },
                                        onBlur: triggerValidation,
                                        validate: (val) => {
                                            const addr1 = getValues("root.addressLine1");
                                            return val !== addr1;
                                        }
                                    })}
                                    type="text"
                                    id="addressLine2Input"
                                    className="form-control"
                                />
                                {errors.addressLine2 &&
                                    errors.addressLine2.message instanceof String && (
                                        <p className="error-text">{errors.addressLine2.message}</p>
                                    )}
                                {errors.addressLine2?.type === "validate" && (
                                    <p className="error-text">
                                        {npdiForm.addressLine2CannotMatchAddressLine1}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={`form-group col-xs-12 col-sm-6 ${
                                    errors.city && "has-error"
                                }`}
                            >
                                {!showOtherCityState ? (
                                    <label htmlFor="cityInput" className="control-label">
                                        {npdiForm.mailingAddress.city}
                                    </label>
                                ) : (
                                    <label htmlFor="cityInput" className="control-label">
                                        {npdiForm.mailingAddress.combinedCityState}
                                    </label>
                                )}
                                <input
                                    {...register("city", {
                                        required: npdiForm.cityRequired,
                                        maxLength: {
                                            value: 20,
                                            message: npdiForm.cityInvalidLength
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z' ]+$/,
                                            message: npdiForm.cityInvalidPattern
                                        },
                                        onBlur: triggerValidation
                                    })}
                                    type="text"
                                    id="cityInput"
                                    className="form-control"
                                />
                                {errors.city && errors.city.message instanceof String && (
                                    <p className="error-text">{errors.city.message}</p>
                                )}
                            </div>
                            {!showOtherCityState && (
                                <div
                                    className={`form-group col-xs-12 col-sm-6 ${
                                        errors.state && "has-error"
                                    }`}
                                >
                                    <label htmlFor="stateInput" className="control-label">
                                        {npdiForm.mailingAddress.state}
                                    </label>
                                    <div className="custom-select form-control">
                                        <select
                                            {...register("state", {
                                                required: npdiForm.stateRequired,
                                                onBlur: triggerValidation
                                            })}
                                            id="stateInput"
                                            className="form-control"
                                        >
                                            <option defaultValue=""></option>
                                            {npdiData?.states?.map((state) => {
                                                return (
                                                    <option
                                                        value={state.code}
                                                        key={`option_${state.description}`}
                                                    >
                                                        {toSentenceCase(state.description)}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    {errors.state && errors.state.message instanceof String && (
                                        <p className="error-text">{errors.state.message}</p>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="row">
                            {!showOtherZipcode && (
                                <div
                                    className={`form-group col-xs-12 col-sm-6 ${
                                        errors.zipcode && "has-error"
                                    }`}
                                >
                                    <label htmlFor="zipcodeInput" className="control-label">
                                        {npdiForm.mailingAddress.zipcode}
                                    </label>
                                    <MaskInput
                                        {...register("zipcode", {
                                            required: npdiForm.zipcodeRequired,
                                            maxLength: {
                                                value: 5,
                                                message: npdiForm.zipcodeInvalidPattern
                                            },
                                            onBlur: triggerValidation,
                                            onChange: (e) => {
                                                const val = getValues("root.zipcode");
                                                if (e.target.value && !val) {
                                                    setValue("zipcode", e.target.value);
                                                }
                                            }
                                        })}
                                        type="text"
                                        inputMode="numeric"
                                        id="zipcodeInput"
                                        className="form-control"
                                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                                        keepCharPositions={true}
                                        defaultValue={""}
                                    />
                                    {errors.zipcode && errors.zipcode.message instanceof String && (
                                        <p className="error-text">{errors.zipcode.message}</p>
                                    )}
                                </div>
                            )}
                            {showOtherZipcode && (
                                <div
                                    className={`form-group col-xs-12 col-sm-6 ${
                                        errors.zipcode && "has-error"
                                    }`}
                                >
                                    <label htmlFor="zipcodeInput" className="control-label">
                                        {npdiForm.mailingAddress.zipcode}
                                    </label>
                                    <input
                                        {...register("zipcode", {
                                            required: npdiForm.zipcodeRequired,
                                            maxLength: {
                                                value: 10,
                                                message: npdiForm.zipcodeInvalidPattern
                                            },
                                            pattern: {
                                                value: /^[-a-zA-Z0-9 ]+$/,
                                                message: npdiForm.zipcodeInvalidSpecialChar
                                            },
                                            onBlur: triggerValidation
                                        })}
                                        type="text"
                                        inputMode="numeric"
                                        id="zipcodeInput"
                                        className="form-control"
                                    />
                                    {errors.zipcode && errors.zipcode.message instanceof String && (
                                        <p className="error-text">{errors.zipcode.message}</p>
                                    )}
                                </div>
                            )}
                            <div
                                className={`form-group col-xs-12 col-sm-6 ${
                                    errors.country && "has-error"
                                }`}
                            >
                                <label htmlFor="countryInput" className="control-label">
                                    {npdiForm.mailingAddress.country}
                                </label>
                                <div className="custom-select form-control">
                                    <select
                                        {...register("country", {
                                            required: npdiForm.countryRequired,
                                            onChange: updateAddressVisibility,
                                            onBlur: triggerValidation
                                        })}
                                        id="countryInput"
                                        className="form-control"
                                    >
                                        {npdiData?.countries?.map((country) => {
                                            return (
                                                <option
                                                    key={`option_${country.code}`}
                                                    selected={country.code === "US"}
                                                    value={country.code}
                                                >
                                                    {toSentenceCase(country.description)}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {errors.country && errors.country.message instanceof String && (
                                    <p className="error-text">{errors.country.message}</p>
                                )}
                            </div>
                        </div>
                        {npdiData?.lawEnforcementOfficer && (
                            <div>
                                <header>
                                    <h2 id="law-officer-header">
                                        {" "}
                                        {npdiForm.employmentInfo.lawOfficer}
                                    </h2>
                                </header>
                                <div className="row">
                                    <div
                                        className={`form-group col-xs-12 col-sm-6 ${
                                            errors.lawOfficer && "has-error"
                                        }`}
                                    >
                                        <div
                                            aria-label="law-officer-header"
                                            className="input-row"
                                            role="radiogroup"
                                        >
                                            <label className="radio-inline panel-title collapsed">
                                                <input
                                                    {...register("lawOfficer", {
                                                        required: npdiForm.lawOfficerRequired,
                                                        onBlur: triggerValidation
                                                    })}
                                                    type="radio"
                                                    value="Y"
                                                />
                                                <span style={{ verticalAlign: "text-bottom" }}>
                                                    {npdiForm.labels.yes}
                                                </span>
                                            </label>
                                            <label
                                                className="radio-inline panel-title collapsed"
                                                style={{ marginLeft: "10px" }}
                                            >
                                                <input
                                                    {...register("lawOfficer", {
                                                        required: npdiForm.lawOfficerRequired,
                                                        onBlur: triggerValidation
                                                    })}
                                                    type="radio"
                                                    value="N"
                                                />
                                                <span style={{ verticalAlign: "text-bottom" }}>
                                                    {npdiForm.labels.no}
                                                </span>
                                            </label>
                                        </div>
                                        {errors.lawOfficer &&
                                            errors.lawOfficer.message instanceof String && (
                                                <p className="error-text">
                                                    {errors.lawOfficer.message}
                                                </p>
                                            )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {showCTPEC && (
                            <div>
                                <header>
                                    <h3> {npdiForm.employmentInfo.CTPec}</h3>
                                </header>
                                <NpdiPayStubInfo
                                    stateAgency={true}
                                    register={register}
                                    errors={errors}
                                    npdiForm={npdiForm}
                                    triggerValidation={triggerValidation}
                                    npdiData={npdiData}
                                />
                            </div>
                        )}
                        {showStateAgency && (
                            <div>
                                <h2 id="state-agency-heading">
                                    {npdiForm.employmentInfo.employerQuestion}
                                </h2>
                                <div
                                    role="radiogroup"
                                    className={`panel-group ${errors.stateAgency && "has-error"}`}
                                >
                                    <div className="panel panel-default margin-bottom-100">
                                        <header className="panel-heading form-group">
                                            <label
                                                className="radio-inline panel-title"
                                                htmlFor="stateAgencyYesInput"
                                                onChange={() => setStateAgencySwitch("Y")}
                                            >
                                                <input
                                                    {...register("stateAgency", {
                                                        required: npdiForm.stateAgencyRequired
                                                    })}
                                                    aria-describedby="state-agency-heading"
                                                    id="stateAgencyYesInput"
                                                    value="Y"
                                                    type="radio"
                                                />
                                                <span style={{ verticalAlign: "text-bottom" }}>
                                                    {npdiForm.labels.yes}
                                                </span>
                                            </label>
                                        </header>
                                    </div>
                                    {stateAgencySwitch === "Y" && (
                                        <div className="padding-100">
                                            <header>
                                                <h3> {npdiForm.employmentInfo.CTPec}</h3>
                                            </header>
                                            <NpdiPayStubInfo
                                                stateAgency={true}
                                                register={register}
                                                errors={errors}
                                                npdiForm={npdiForm}
                                                triggerValidation={triggerValidation}
                                                npdiData={npdiData}
                                            />
                                        </div>
                                    )}
                                    <div className="panel panel-default margin-bottom-100">
                                        <header className="panel-heading form-group">
                                            <label
                                                className="radio-inline panel-title"
                                                htmlFor="stateAgencyNoInput"
                                                onChange={() => setStateAgencySwitch("N")}
                                            >
                                                <input
                                                    {...register("stateAgency", {
                                                        required: npdiForm.stateAgencyRequired
                                                    })}
                                                    aria-describedby="state-agency-heading"
                                                    id="stateAgencyNoInput"
                                                    value="N"
                                                    type="radio"
                                                />
                                                <span style={{ verticalAlign: "text-bottom" }}>
                                                    {npdiForm.labels.no}
                                                </span>
                                            </label>
                                        </header>
                                    </div>
                                    {stateAgencySwitch === "N" && (
                                        <div className="padding-100">
                                            <header>
                                                <h3>{npdiForm.employmentInfo.select}</h3>
                                            </header>
                                            <NpdiPayStubInfo
                                                stateAgency={false}
                                                register={register}
                                                errors={errors}
                                                npdiForm={npdiForm}
                                                triggerValidation={triggerValidation}
                                                npdiData={npdiData}
                                            />
                                        </div>
                                    )}
                                    {errors.stateAgency &&
                                        errors.stateAgency.message instanceof String && (
                                            <p className="error-text">
                                                {errors.stateAgency.message}
                                            </p>
                                        )}
                                </div>
                            </div>
                        )}
                        <Registration />
                    </div>
                </form>
            </div>
        </main>
    );
};

export default NpdiForm;
