import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import { chooseDefaultPlanAndRefresh } from "../../services/oneId/primaryPlanService";

const useChooseDefaultPlanAndRefresh = (indId: string, dbName: string, accuCode: string) => {
    const params = {
        indId,
        dbName,
        accuCode
    };
    return useQuery({
        queryKey: [QUERY_KEYS.CHOOSE_DEFAULT_PLAN_AND_REFRESH, params],
        queryFn: async () => {
            const { data } = await chooseDefaultPlanAndRefresh(params);
            return data || [];
        },
        enabled: indId > "" && dbName > "" && accuCode > ""
    });
};

export default useChooseDefaultPlanAndRefresh;
