import ReferenceData from "../../constants/ReferenceData";
import { executeGet, executePost } from "../../utils/appUtils";
import StringUtil from "core-ui/client/src/app/StringUtil";

const handleSuccess = (res) => {
    if (res && res.data) {
        return res.data;
    }
};

const handleError = (err, service) => {
    console.log(`${service} Error: ${err.data}`);
    throw err;
};

export const getCustomization = (authenticated, featureName) => {
    const accu = authenticated && globalThis.accu !== "Empower" ? globalThis.accu : "Default";
    //replace url accu and feature name
    const url = StringUtil.supplant(__styleEnv__ + ReferenceData.DEEPLINK.GET_CUSTOMIZATION, {
        accu,
        featureName
    });

    return executeGet(url)
        .then(handleSuccess)
        .catch((err) => handleError(err, "getCustomizationFault"));
};

export const retrieveAllPlansWithIndid = () => {
    const url = ReferenceData.DEEPLINK.GET_ALL_PLANS;

    return executeGet(url)
        .then(handleSuccess)
        .catch((err) => handleError(err, "retrieveAllPlansWithIndidFault"));
};

export const getDestination = (params) => {
    const url = StringUtil.supplant(ReferenceData.DEEPLINK.GET_DESTINATION, { gaId: params.gaID });

    return executePost(url, "getDestinationFault", params)
        .then(handleSuccess)
        .catch((err) => handleError(err, "getDestinationFault"));
};

export const getPlansAvailableForPrimaryPlan = () => {
    const url = ReferenceData.DEEPLINK.GET_PLANS_FOR_PRIMARY_PLAN;

    return executeGet(url)
        .then(handleSuccess)
        .catch((err) => handleError(err, "getPlansAvailableForPrimaryPlanFault"));
};
