/* eslint-disable */
const linkablePlansTemplate = `
<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 account-selection linkable-plans-template">
    <header class="contained-form-header margin-top-default">
        <h1>
            {{ 'linkablePlans.header' | translate }}
        </h1>
        <p> {{ 'linkablePlans.intro' | translate }} </p>
    </header>
    <div id="statementsDocs-spinner" class="loaderBackground just-element"
        ng-if="LinkablePlansSelectionController.loading">
        <div class="loader"></div>
    </div>
    <form class="with-padding with-top-margin margin-bottom-200" role="form" name="primaryPlanSelectionForm" novalidate>
        <div class="inner-container with-padding with-shadow clearfix">
            <div class="current-user-plans" ng-if="currentUserPlans">
                <div class="flex-heading ng-scope">
                    <label class="control-label" ng-if="currentUserPlans.length >1">{{ 'linkablePlans.thesePlans' |
                        translate }}</label>
                    <label class="control-label" ng-if="currentUserPlans.length == 1">{{ 'linkablePlans.thisPlan' |
                        translate }}</label>
                    <button 
                        class="btn btn-link btn-no-style linkable-plans-tooltip" 
                        tooltip-placement="right"
                        tooltip-trigger="focus"
                        uib-tooltip-html="'<ul><li>{{ \'linkablePlans.t1\' | translate }}</li><br><li>{{ \'linkablePlans.t2\' | translate }}</li></ul>'"
                    >{{'linkablePlans.learnMore' | translate}}</button>
                </div><!-- end ngIf: !ctrl.uploadInProgress() -->
                <div class="well">
                    <div class="form-group checkbox ng-scope text-center">
                        <label>
                            <span data-ng-repeat="currentPlan in currentUserPlans"
                                style="font-size: 16px; white-space:normal">
                                {{currentPlan.planName}}
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <div ng-if="plansMapWithIndIdDbName">
                <label class="control-label">{{ 'linkablePlans.selectOtherPlans' | translate }}</label>
                <div class="well well--blue">
                    <span class="em-info-icon"></span>
                    <span>
                        {{ 'linkablePlans.lockInfo1' | translate }}
                        <img ng-src="{{styleEnv}}customizations/Default/images/padlock.svg">
                        {{ 'linkablePlans.lockInfo2' | translate }}
                    </span>
                </div>
                <div class="well well--outline" data-ng-repeat="(key, value) in plansMapWithIndIdDbName">
                    <div>
                        <img ng-src="{{styleEnv}}customizations/Default/images/padlock.svg" class="oneid-lock" 
                        data-ng-show="LinkablePlansSelectionController.checkIfLockNeeded(key)">
                        <img ng-src="{{styleEnv}}customizations/Default/images/login/checkmark.svg" 
                        data-ng-hide="LinkablePlansSelectionController.checkIfLockNeeded(key)">
                        <div>
                            <div data-ng-repeat="planValue in value">{{planValue.planName}}</div>
                        </div>
                        <button
                            class="btn btn-primary btn-primary--outline"
                            ng-click="LinkablePlansSelectionController.onVerifyAccountClick(key)"
                            data-ng-show="LinkablePlansSelectionController.verifyAccountButtonVisibility(key)"
                        >
                            {{ 'linkablePlans.verifyThisAccount' | translate }}
                        </button>
                    </div>
                    <div data-ng-show="LinkablePlansSelectionController.isEligibleForHidingSectionVisibility(key)">
                        <p>{{ 'linkablePlans.zeroBalanceAccount' | translate }}</p>
                        <p>{{ 'linkablePlans.reinitiateZeroBalanceAccount' | translate }}</p>
                        <div>
                            <button
                                class="btn btn-primary btn-primary--outline"
                                ng-click="LinkablePlansSelectionController.verifyAccount(key)"
                            >
                                {{ 'linkablePlans.keepThisAccount' | translate }}
                            </button>
                            <button
                                class="btn btn-primary"
                                ng-click="LinkablePlansSelectionController.hideAccount(key)"
                            >
                                {{ 'linkablePlans.hideThisAccount' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row cta investment-button-row">
                <button
                    id="submitButton" type="submit"
                    class="col-12 btn btn-primary oneid-button-large"
                    ng-disabled="!(LinkablePlansSelectionController.checkForEnabling())"
                    ng-click="LinkablePlansSelectionController.continueClick()"
                >
                    {{ 'linkablePlans.continue' | translate }}
                </button>
            </div>
        </div>
    </form>
</div>

`;
export default linkablePlansTemplate;
