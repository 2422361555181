import React, { Fragment, useCallback, useState } from "react";
import type { FocusEvent } from "react";
import { useTranslations } from "../../queries";
import PasswordErrors from "./PasswordErrors";
import UsernameErrors from "./UsernameErrors";
import type { RegisterCredentialsProps, SetupTranslations } from "./types";
import hasNumber from "./utils/hasNumber";
import hasThreeLetters from "./utils/hasThreeLetters";
import hasNoSpace from "./utils/hasNoSpace";
import hasAllowedCharactersUsername from "./utils/hasAllowedCharactersUsername";
import hasUppercase from "./utils/hasUppercase";
import hasLowercase from "./utils/hasLowercase";
import hasSpecialChar from "./utils/hasSpecialChar";

interface FocusState {
    confirmPassword: boolean;
    password: boolean;
    username: boolean;
}

const DEFAULT_FOCUS_STATE = {
    username: false,
    password: false,
    confirmPassword: false
};

const RegisterCredentials = ({
    register,
    getValues,
    trigger,
    errors
}: RegisterCredentialsProps) => {
    const { accountSetup }: { accountSetup: SetupTranslations } = useTranslations();
    const [focusState, setFocusState] = useState<FocusState>(DEFAULT_FOCUS_STATE);

    /**
     * Validation rules for username field. A string must be returned for react-hook-form to
     * add the error to the errors object. Undefined must be returned to remove the error.
     */
    const USERNAME_RULES = {
        hasNoSpace: (value: string) =>
            hasNoSpace(value) ? undefined : accountSetup.usernameNoSpace,
        lengthRequirement: (value: string) =>
            value.length < 6 ? accountSetup.usernameMinLength : undefined,
        hasThreeLetters: (value: string) =>
            hasThreeLetters(value) ? undefined : accountSetup.usernameLetterPattern,
        hasNumber: (value: string) =>
            hasNumber(value) ? undefined : accountSetup.usernameNumberPattern,
        hasAllowedCharactersUsername: (value: string) =>
            hasAllowedCharactersUsername(value)
                ? undefined
                : accountSetup.usernameAllowedSpecialCharacters
    };

    /**
     * Validation rules for password field. A string must be returned for react-hook-form to
     * add the error to the errors object. Undefined must be returned to remove the error.
     */
    const PASSWORD_RULES = {
        lengthRequirement: (value: string) =>
            value.length < 8 || value.length > 16 ? accountSetup.passwordMinLength : undefined,
        passwordConditions: (value: string) => {
            const upper = hasUppercase(value);
            const lower = hasLowercase(value);
            const digit = hasNumber(value);
            const specialChar = hasSpecialChar(value);
            if ([upper, lower, digit, specialChar].filter(Boolean).length < 3) {
                return [
                    accountSetup.passwordCondition0,
                    upper ? "" : accountSetup.passwordUppercasePattern,
                    lower ? "" : accountSetup.passwordLowercasePattern,
                    digit ? "" : accountSetup.passwordNumberPattern,
                    specialChar ? "" : accountSetup.passwordSpecialCharPattern
                ].join("|");
            }
            return undefined;
        },
        matchUsername: (value: string) =>
            value === getValues("username") ? accountSetup.passwordNotMatchUsername : undefined
    };

    /**
     * Validation rules for confirm password field. A string must be returned for react-hook-form to
     * add the error to the errors object. Undefined must be returned to remove the error.
     */
    const CONFIRM_PASSWORD_RULES = {
        confirmPasswordNoMatch: (value: string) =>
            value !== getValues("password") ? accountSetup.confirmPasswordNotMatch : undefined
    };

    const handleFocus = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            const name = event.target.name as keyof FocusState;
            trigger(name);
            setFocusState((prev) => ({ ...prev, [name]: false }));
        },
        [trigger]
    );

    const handleBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
        setFocusState((prev) => ({ ...prev, [event.target.name]: true }));
    }, []);

    return (
        <Fragment>
            <div
                className={`form-group ${focusState.username && errors.username && "has-error"}`}
                data-testid="username-container"
            >
                <label id="usernameInputId" htmlFor="usernameInput" className="control-label">
                    {accountSetup.userInfo.username}
                </label>
                <input
                    {...register("username", {
                        required: accountSetup.usernameRequired,
                        maxLength: 16,
                        validate: USERNAME_RULES
                    })}
                    autoComplete="off"
                    data-testid="username-input"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    type="text"
                    className="form-control"
                    id="usernameInput"
                    aria-describedby="userNameRules"
                    aria-labelledby="usernameInputId"
                />
                <UsernameErrors
                    errors={errors}
                    highlightErrors={focusState.username && errors.username !== undefined}
                />
            </div>
            <div className={`form-group ${focusState.password && errors.password && "has-error"}`}>
                <label id="passwordInputId" htmlFor="passwordInput" className="control-label">
                    {accountSetup.userInfo.password}
                </label>
                <input
                    {...register("password", {
                        required: accountSetup.passwordRequired,
                        maxLength: 16,
                        validate: PASSWORD_RULES
                    })}
                    autoComplete="off"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    type="password"
                    className="form-control"
                    id="passwordInput"
                    aria-describedby="passwordRules"
                    aria-labelledby="passwordInputId"
                />

                <PasswordErrors
                    errors={errors}
                    highlightErrors={focusState.password && errors.password !== undefined}
                />
            </div>

            <div
                className={`form-group ${
                    focusState.confirmPassword && errors.confirmPassword && "has-error"
                }`}
            >
                <label htmlFor="confirmPasswordInput" className="control-label">
                    {accountSetup.userInfo.confirmPassword}
                </label>
                <input
                    {...register("confirmPassword", {
                        required: accountSetup.confirmPasswordRequired,
                        maxLength: 16,
                        validate: CONFIRM_PASSWORD_RULES
                    })}
                    autoComplete="off"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    type="password"
                    id="confirmPasswordInput"
                    className="form-control"
                    aria-describedby="confirmPasswordRules"
                />
                <ul
                    className={
                        focusState.confirmPassword && errors.confirmPassword !== undefined
                            ? "field-errors"
                            : ""
                    }
                >
                    {errors.confirmPassword?.types?.required && (
                        <li>{errors.confirmPassword.types.required}</li>
                    )}
                    {errors.confirmPassword?.types?.confirmPasswordNoMatch && (
                        <li>{errors.confirmPassword.types.confirmPasswordNoMatch}</li>
                    )}
                </ul>
            </div>
        </Fragment>
    );
};

export default RegisterCredentials;
