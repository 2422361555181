/**
 *
 * @name app.registration.events.registration
 * @copyright 2025 Empower Retirement
 * @description
 *
 *  These are the events for registration views
 */

const root = "registration.";

const RegistrationEvents = {
    //registrationWithoutPin
    PGE_VIEW_NO_PIN: root + "account_verification_do_not_have_a_pin_view",
    SUCCESSFUL_SUBMIT_NO_PIN: root + "account_verification_do_not_have_a_pin_submit",
    ATTEMPT_SUBMIT_NO_PIN: root + "account_verification_do_not_have_a_pin_submit_attempt",
    //registrationWithPin
    PGE_VIEW_PIN: root + "account_verification_have_a_pin_view",
    SUCCESSFUL_SUBMIT_PIN: root + "acount_verification_have_a_pin_submit",
    ATTEMPT_SUBMIT_PIN: root + "acount_verification_have_a_pin_submit_attempt",
    //registrationWithPlanEnrollmentCode
    PGE_VIEW_ENROLLMENT_CODE: root + "account_verification_have_an_enrollment_code_view",
    SUCCESSFUL_SUBMIT_ENROLLMENT_CODE: root + "acount_verification_have_an_enrollment_submit",
    ATTEMPT_SUBMIT_ENROLLMENT_CODE: root + "acount_verification_have_an_enrollment_submit_attempt"
};

export default RegistrationEvents;
