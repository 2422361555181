import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { EnterpriseChatComponent, EnterpriseChatPreLoginComponent } from "gw-shared-components";
import useAuthData from "../../queries/useAuthData";
import { useAccountDetailsByIds } from "../../queries/";
import useGroupTxnAccess from "../../queries/useGroupTxnAccess";
import usePrimaryPlan from "../../queries/usePrimaryPlan";
import useAccuCustomization from "../../queries/useAccuCustomization";

const LANGUAGE = "en_US";

const EnterpriseChatContainer = () => {
    const { data: authDataResults, isLoading: authDataLoading } = useAuthData();
    const { data: primaryPlanData, isLoading: isPrimaryPlanLoading } = usePrimaryPlan(
        authDataResults?.authenticated
    );
    const { data: accountCustomization, isLoading: isAccountCustomizationLoading } =
        useAccuCustomization(authDataResults?.accuCode);
    const { gaId: groupId, indId: individualId } = primaryPlanData ?? {};
    const defaultCustomization = {};
    const { data: txnAccess, isLoading: isTxnLoading } = useGroupTxnAccess(
        groupId,
        individualId,
        "WNCCHB,WNACHB,WNACHT"
    );

    const { data: accDetailsResults, isLoading: accDetailsLoading } = useAccountDetailsByIds({
        groupId,
        individualId
    });
    const isPAE = globalThis.integratedEligibility.isEmulator;

    if (!authDataResults?.authenticated) {
        return <EnterpriseChatPreLoginComponent />;
    }

    if (
        !accDetailsResults ||
        accDetailsLoading ||
        authDataLoading ||
        isPrimaryPlanLoading ||
        isTxnLoading ||
        isAccountCustomizationLoading ||
        !txnAccess
    ) {
        return null;
    }

    const { firstName, middleName, lastName, name } = accDetailsResults;
    const { WNCCHB, WNACHB, WNACHT } = txnAccess;

    const accountDetails = {
        accountDetails: { firstName, middleName: middleName ?? "", lastName, name: name ?? "" }
    };
    const activeGA = {
        individualId: individualId ?? "",
        groupId: groupId ?? ""
    };

    const footerTranslations = selectTranslations("footer");

    return (
        <EnterpriseChatComponent
            account={accountDetails}
            activeGroupAccount={activeGA}
            accuCustomization={accountCustomization ?? defaultCustomization}
            authenticated
            chatBotEnable={WNACHB === "true"}
            enterpriseChatEnable={WNCCHB === "true"}
            isEmulator={isPAE}
            language={LANGUAGE}
            liveChatEnable={WNACHT === "true"}
            selectedLanguage={LANGUAGE}
            translations={{ footer: footerTranslations }}
        />
    );
};

export default EnterpriseChatContainer;
