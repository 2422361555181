import StringUtil from "core-ui/client/src/app/core/util/StringUtil";
import ReferenceData from "../../constants/ReferenceData";
import { executeGet } from "../../utils/appUtils.js";

/**
 * Return higher level for query hook
 * @param {*} res
 * @returns
 */
const handleRetrieveSuccess = (res) => {
    if (res && res.data) {
        return res;
    }
};

const handleError = (err, service) => {
    console.log(`${service} Error: ${err.data}`);
    throw err;
};

export const retrievePlansAvailableForPrimaryPlan = () => {
    const url = __baseApiUrl__ + ReferenceData.RETRIEVE_PLANS_AVAILALE_FOR_PRIMARY_PLAN;

    return executeGet(url)
        .then(handleRetrieveSuccess)
        .catch((err) => handleError(err, "retrievePlansAvailableForLPrimaryPlan"));
};

export const chooseDefaultPlanAndRefresh = (params) => {
    const payload = {
        indId: params.indId,
        dbName: params.dbName,
        accuCode: params.accuCode,
        updatedefault: "Y"
    };

    const url =
        __baseApiUrl__ +
        StringUtil.supplant(ReferenceData.CHOOSE_DEFAULT_AND_REFRESH_PLAN, payload);

    return executeGet(url)
        .then(handleRetrieveSuccess)
        .catch((err) => handleError(err, "chooseDefaultAndRefresh"));
};
