import React from "react";
import type { FieldErrorsImpl } from "react-hook-form";
import type { RegisterFieldErrors } from "./types";

interface PasswordErrorsProps {
    errors: FieldErrorsImpl<RegisterFieldErrors>;
    highlightErrors: boolean;
}

const PasswordErrors = ({ errors, highlightErrors }: PasswordErrorsProps) => {
    if (!errors.password?.types) return;

    const { required, lengthRequirement, passwordConditions, matchUsername } =
        errors.password.types;
    const [hasThreeConditions, hasUppercase, hasLowercase, hasNumber, hasSpecialChar] =
        typeof passwordConditions === "string" ? passwordConditions.split("|") : [];

    return (
        <ul
            className={highlightErrors ? "field-errors" : ""}
            data-testid="password-errors"
            id="passwordRequiredError"
        >
            {required && <li data-testid="password-required">{required}</li>}
            {lengthRequirement && <li data-testid="password-length">{lengthRequirement}</li>}
            {hasThreeConditions && (
                <li data-testid="password-condition">
                    {hasThreeConditions}
                    <ul>
                        {hasUppercase && <li data-testid="password-upper">{hasUppercase}</li>}
                        {hasLowercase && <li data-testid="password-lower">{hasLowercase}</li>}
                        {hasNumber && <li data-testid="password-number">{hasNumber}</li>}
                        {hasSpecialChar && (
                            <li data-testid="password-special-char">{hasSpecialChar}</li>
                        )}
                    </ul>
                </li>
            )}
            {matchUsername && <li data-testid="password-username-match">{matchUsername}</li>}
        </ul>
    );
};

export default PasswordErrors;
