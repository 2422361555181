import {
    deleteCookie,
    resetAccuService,
    updateAccuCookie
} from "../../../services/accuCodeService";
import primaryPlan from "../../../services/common/primaryPlan";
import {
    getDestination,
    getPlansAvailableForPrimaryPlan,
    retrieveAllPlansWithIndid
} from "../../../services/deepLink/deepLinkService";
import { callLogoutSvc } from "core-ui/client/react/core/services/logoutService";
import { updateDefaultPlanSvc } from "core-ui/client/react/core/services/accountService";
import authResetFlow from "../../loginForm/helpers/authenticate/authResetFlow";
import type { PlanMapWithIndId } from "../../../services/redirect/types";

interface PlansForPrimaryPlan {
    plansMapWithIndid: PlanMapWithIndId;
}

interface JsonPostForDataType {
    authTxnCodes: string;
    deepLinkedUrl: string;
    gaID: string;
    newAuthTxnCodes: string;
    newDeepLinkedUrl: string;
    newTargetTxnCode: string;
    targetTxnCode: string;
}

interface DeepLinkData {
    deepLinkEligible: boolean;
    destinationUrl: string;
    indID: string;
}

const evaluateLoggingOut = async (authStatus, toLogin, toRegister) => {
    const href = window.location.href;
    const loggingOutParamIdx = href.indexOf("loggingOut");
    if (loggingOutParamIdx !== -1) {
        const params = href.substring(loggingOutParamIdx, href.length).split("=");
        if (params[1] == "true") {
            try {
                await callLogoutSvc();
                //await callLogoutPartAuth();
            } catch (e) {
                console.error(e);
            }

            if (!authStatus.registered && authStatus.authenticated === true) {
                toRegister(true);
            } else {
                toLogin(true);
            }

            return true;
        }
    }

    return false;
};

const evaluateCurrentSessionAndDeepLink = async (
    data,
    jsonPostForDest,
    freemiumOverride,
    redirect
) => {
    const primaryPlanData = await primaryPlan();
    const gaId = globalThis.deepLinkParams.gaId || sessionStorage.getItem("gId");

    //checking if deeplink plan is not the same as the primaryplan
    if (gaId !== primaryPlanData.data.gaId) {
        //starting to switch context based on the plan from the url
        const plansForPrimaryPlan: PlansForPrimaryPlan = await getPlansAvailableForPrimaryPlan();
        if (plansForPrimaryPlan.plansMapWithIndid) {
            for (const [_, value] of Object.entries(plansForPrimaryPlan.plansMapWithIndid)) {
                if (gaId === value[0].gaId) {
                    //getting data to call the switch service
                    const params = {
                        indId: value[0].indId,
                        dbName: value[0].dbname,
                        accuCode: value[0].pptwebAccuCode,
                        updatedefault: "N",
                        gaId: value[0].gaId
                    };
                    const refreshData = await updateDefaultPlanSvc(params);
                    const accu = refreshData?.primaryPlan.accuCode;

                    globalThis.accu = accu;
                    globalThis.css.accu = accu;

                    await authResetFlow(accu);

                    await resetAccuService(refreshData?.primaryPlan.accuCode);

                    // updating cookies with new accu from the switched context
                    updateAccuCookie(refreshData?.primaryPlan.accuCode);
                    deleteCookie("PM-ACCU-TOKEN", "/", null);
                }
            }
        }
    }

    jsonPostForDest.targetTxnCode = data.menuAccessCode;
    jsonPostForDest.newTargetTxnCode = data.newMenuAccessCode || null;
    jsonPostForDest.authTxnCodes = data.authTxnCodes;
    jsonPostForDest.newAuthTxnCodes = data.newAuthTxnCodes || null;
    jsonPostForDest.deepLinkedUrl = data.url;
    jsonPostForDest.newDeepLinkedUrl = data.newUrl || null;

    //doing redirection to the deeplink with the switched context
    redirectToDeepLinkDest(jsonPostForDest, freemiumOverride, redirect);
};

const redirectToDeepLinkDest = async (jsonPostForDest, freemiumOverride, redirect) => {
    const isFreemium = window.sessionStorage.getItem("isFreemium");
    const handleError = () => {
        redirect(
            {
                destinationUrl: "participant/accounts/#/",
                state: ""
            },
            {
                flowName: "deepLink"
            }
        );
    };

    if (isFreemium && freemiumOverride) {
        window.location.href = globalThis.deeplinkCust.url.replace("participant/home", "dashboard");
    } else {
        const deepLinkData: DeepLinkData = await getDestination(jsonPostForDest);

        if (deepLinkData.deepLinkEligible) {
            const deepLinkURL = globalThis.deepLinkParams.params;
            let urlToBeRedircted = globalThis.deeplinkCust.url;

            urlToBeRedircted = urlToBeRedircted.replace(":queryParams", deepLinkURL);
            urlToBeRedircted = urlToBeRedircted.replace(":indId", deepLinkData.indID);
            urlToBeRedircted = urlToBeRedircted.replace(":gaId", jsonPostForDest.gaID);
            urlToBeRedircted = urlToBeRedircted.replace(":groupId", jsonPostForDest.gaID);

            window.location.href = "../../" + urlToBeRedircted;
        } else if (!deepLinkData.deepLinkEligible && deepLinkData.destinationUrl) {
            /**
             * The below if condition is that if the user is coming up with deeplink with secondary plan which is in enrollment then we have to supercede the deeplink and
             * redirect the user to the enrollment flow.
             */
            window.location.href = "../../" + deepLinkData.destinationUrl;
        } else {
            handleError();
        }
    }
};

const handleDeepLinkAuth = async (data, authStatus, toLogin, toRegister, redirect) => {
    const freemiumOverride = !!data.freemiumOverride;
    globalThis.deeplinkCust = data;

    const jsonPostForDest: JsonPostForDataType = {
        targetTxnCode: "",
        newTargetTxnCode: "",
        authTxnCodes: "",
        newAuthTxnCodes: "",
        gaID: "",
        deepLinkedUrl: "",
        newDeepLinkedUrl: ""
    };
    jsonPostForDest.targetTxnCode = data.menuAccessCode;
    jsonPostForDest.newTargetTxnCode = data.newMenuAccessCode ? data.newMenuAccessCode : null;
    jsonPostForDest.authTxnCodes = data.authTxnCodes;
    jsonPostForDest.newAuthTxnCodes = data.newAuthTxnCodes ? data.newAuthTxnCodes : null;

    const context = data.url.split("#")[0];
    const hash = data.url.split("#")[1];
    jsonPostForDest.deepLinkedUrl = globalThis.deepLinkParams.params
        ? `${context}?${globalThis.deepLinkParams.params}#${hash}`
        : data.url;
    jsonPostForDest.newDeepLinkedUrl = data.newUrl ? data.newUrl : null;

    let gaId = globalThis.deepLinkParams.gaId;
    jsonPostForDest.gaID = gaId;
    const isLoggingOutFlow = await evaluateLoggingOut(authStatus, toLogin, toRegister);

    if (!isLoggingOutFlow) {
        if (!gaId) {
            const { data: primaryPlanData } = await primaryPlan();
            gaId = primaryPlanData?.gaId;

            if (gaId) {
                const plansMapWithIndid = await retrieveAllPlansWithIndid();

                const hasMultiplePlans = Object.keys(plansMapWithIndid).length > 1;
                if (hasMultiplePlans) {
                    return redirect(
                        {
                            destinationUrl: "d",
                            deepLinkedUrl:
                                "participant/#/d/" + globalThis.deepLinkParams.featureName,
                            plansMapWithIndid,
                            state: ""
                        },
                        { flowName: "ALL" }
                    );
                } else {
                    jsonPostForDest.gaID = gaId;
                    const groupClient = String(primaryPlanData.gaId).split("-");
                    const groupClientId = groupClient[0];
                    // eslint-disable-next-line no-undef
                    if (globalThis.css) {
                        globalThis.css.group = gaId;
                        // eslint-disable-next-line no-undef
                        globalThis.css.groupClient = groupClientId;
                    }
                }
            }

            const customEvent = new CustomEvent("handleLoginSetAccountDetails", {
                detail: global
            });
            window.dispatchEvent(customEvent);

            redirectToDeepLinkDest(jsonPostForDest, freemiumOverride, redirect);
        } else {
            evaluateCurrentSessionAndDeepLink(data, jsonPostForDest, freemiumOverride, redirect);
        }
    }
};

export default handleDeepLinkAuth;
