import ExternalLogger from "core-ui/client/src/app/ExternalLogger";

import oneIdEvents from "./events/OneIdEvents";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";

const PrimaryPlanSelectionCtrl = function (
    $cookies,
    $q,
    $rootScope,
    $scope,
    $stateParams,
    $translate,
    $window,
    AccuCodeService,
    DefaultCustomizationRetrService,
    PreLoginAccuRetrievalService,
    PreLoginContactInfoService,
    PrimaryPlanSelectionFactory,
    ReSetACCUService,
    eventBus,
    redirectService,
    TransactionAccessRetrService
) {
    const vm = this;
    const logger = ExternalLogger.getInstance("PrimaryPlanSelectionCtrl");
    // Label, continue Button, beaware header and beaware content do not change
    $scope.label = $translate.instant("choosePrimaryPlan.label");
    $scope.continueButton = $translate.instant("choosePrimaryPlan.continuebutton");
    $scope.beAwareHeader = $translate.instant("choosePrimaryPlan.beAwareheader");
    $scope.beAwareContent = $translate.instant("choosePrimaryPlan.beAwareContent");
    $scope.showBeAware = false;

    // set the header and intro based on the oneIdFlow state param
    const flow = $stateParams.oneIdFlow;
    console.log("one idflow", flow);
    if (flow === "linking") {
        $scope.header = $translate.instant("choosePrimaryPlanLinking.header");
        $scope.intro = $translate.instant("choosePrimaryPlanLinking.intro");
    } else {
        $scope.header = $translate.instant("choosePrimaryPlan.header");
        $scope.intro = $translate.instant("choosePrimaryPlan.intro");
    }

    vm.getTxnAccess = function (params) {
        TransactionAccessRetrService.queryWithAccount(
            params,
            function (results) {
                if (results.WNOAIN === "true") {
                    $scope.beAwareHeader = $translate.instant("choosePrimaryPlan.beAwareheader");
                    $scope.beAwareContent = $translate.instant("choosePrimaryPlan.beAware.content");
                    $scope.showBeAware = true;
                }
                vm.loading = false;
            },
            function (err) {
                //Error with transaction access call
                console.log("transaction authoriziation WNOAIN retrieval error: ", err);
                logger.error("transaction authoriziation WNOAIN retrieval error: ", err);
                vm.loading = false;
            }
        );
    };

    vm.error = false;
    vm.init = function () {
        vm.loading = true;
        PrimaryPlanSelectionFactory.retrievePlansAvailableForPrimaryPlan.query({}, function (data) {
            vm.loading = false;
            $scope.plansMapWithIndIdDbName = data.plansMapWithIndid;
            $scope.previousSelectedVal = "none";
            $scope.primaryPlanMapWithInIndid = data.primaryPlanMapWithInIndid;

            for (const [_, item] of Object.entries($scope.primaryPlanMapWithInIndid)) {
                // eslint-disable-next-line no-inner-declarations
                async function setup() {
                    const txnCodeParamWithGroup = {
                        txnCode: "WNOAIN",
                        individualId: item.indId,
                        groupId: item.gaId
                    };

                    vm.getTxnAccess(txnCodeParamWithGroup);
                }

                setup();
            }
        });
    };

    vm.continueClick = function () {
        const deferred = $q.defer();
        const method = "primaryplan selection controller continueClick";
        eventBus.dispatch(oneIdEvents.CHOOSE_PRIMARY_PLAN_CONTD_CLIECKED, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: oneIdEvents.CHOOSE_PRIMARY_PLAN_CONTD_CLIECKED
            }
        });
        vm.loading = true;
        let temp = [];

        temp = vm.primaryPlanChoice.split(":");
        const params = {
            indId: temp[0],
            dbName: temp[1],
            accuCode: temp[2],
            updatedefault: "Y"
        };
        PrimaryPlanSelectionFactory.chooseDefaultAndRefresh.query(
            params,
            //success
            function (data) {
                vm.loading = false;
                if ($window.accu !== data.data.primaryPlan.accuCode) {
                    const newAccuCode = data.data.primaryPlan.accuCode;

                    if (
                        newAccuCode &&
                        $window.accu !== newAccuCode &&
                        $window.supportedACCU.folders.indexOf(newAccuCode) >= 0
                    ) {
                        logger.error(
                            "{0} - Empower Restricted authentication..changing accu to [{1}]",
                            [method, newAccuCode]
                        );
                        $window.accu = newAccuCode;
                        // eslint-disable-next-line no-undef
                        globalThis.css.accu = newAccuCode;

                        //update tomcat
                        logger.error("{0} - updating accu...", [method]);
                        ReSetACCUService.query({ accu: $window.accu }).$promise.then(function () {
                            //set cookie
                            $cookies.remove("accu", { path: "/" });
                            $cookies.put("accu", $window.accu, { path: "/" });

                            $cookies.remove("accu", { path: "/participant" });
                            $cookies.put("accu", $window.accu, { path: "/participant" });

                            $cookies.remove("clientAccu", { path: "/" });
                            $cookies.put("clientAccu", $window.accu, { path: "/" });

                            $cookies.remove("clientAccu", { path: "/participant" });
                            $cookies.put("clientAccu", $window.accu, { path: "/participant" });

                            // added to resolve SR # 03597037   - cookie used for legacy pptweb quicken download feature
                            $cookies.remove("PM-ACCU-TOKEN", { path: "/" });

                            $scope.accu = AccuCodeService.getAccuCode();

                            vm.updateCssAccu($scope.accu);
                            //to reload customizations..

                            logger.debug(method + "- re-loading default customizations..", method);
                            DefaultCustomizationRetrService.query().$promise.then(
                                function (defaultData) {
                                    logger.debug(
                                        method + "- default customizations..",
                                        method,
                                        defaultData
                                    );

                                    logger.debug(
                                        method + "- re-loading preLogin accu data..",
                                        method
                                    );
                                    PreLoginAccuRetrievalService.query({
                                        accu: $scope.accu
                                    }).$promise.then(function (preLoginData) {
                                        logger.debug(
                                            method + "- preLogin accu data..",
                                            method,
                                            preLoginData
                                        );
                                        // eslint-disable-next-line no-undef
                                        $scope.accuCustomization = angular.extend(
                                            {},
                                            defaultData,
                                            preLoginData
                                        );
                                        $rootScope.accuSpecificPrimDb =
                                            $scope.accuCustomization.primaryDatabase.preLoginPrimaryDB;

                                        logger.debug(
                                            method + "- re-loading PreLogin contact info.",
                                            method
                                        );
                                        logger.debug(
                                            method + "- existing siteContactInfo",
                                            $rootScope.siteContactInfo
                                        );
                                        PreLoginContactInfoService.query({
                                            accu: $scope.accu,
                                            accuSpecificPrimDb: $rootScope.accuSpecificPrimDb,
                                            portalType: "TOLLFREE"
                                        }).$promise.then(
                                            function (contactData) {
                                                $rootScope.siteContactInfo = contactData;
                                                logger.debug(
                                                    method + "-  NEW PreLogin contact info..",
                                                    method,
                                                    contactData
                                                );
                                                $rootScope.$broadcast(
                                                    "siteContactInfoUpdated",
                                                    $rootScope.siteContactInfo
                                                );
                                                $rootScope.$broadcast(
                                                    "loginAuthStatusVerified",
                                                    $scope.authenticationStatus
                                                );
                                                $rootScope.$broadcast(
                                                    "customizationUpdated",
                                                    $scope.accuCustomization
                                                );

                                                logger.debug("{0} - Accu code reset to: [{1}]", [
                                                    method,
                                                    $scope.accu
                                                ]);
                                                //#############################################################################

                                                redirectService.redirect(data.data, "ALL");
                                                deferred.resolve();
                                                //#############################################################################
                                            },
                                            function (error) {
                                                logger.error(
                                                    "ERROR - PrimaryPlanSelectionCtrl:PreLoginContactInfoService: ",
                                                    error
                                                );
                                                deferred.reject();
                                            }
                                        );
                                    });
                                }
                            );
                        });
                    } else {
                        redirectService.redirect(data.data, "ALL");
                        deferred.resolve();
                    }
                } else {
                    redirectService.redirect(data.data, "ALL");
                    deferred.resolve();
                }
            },
            function () {
                vm.loading = false;
                vm.error = true;
            }
        );
    };

    vm.disableContinueButton = function () {
        return vm.primaryPlanChoice;
    };

    vm.updateCssAccu = function (val) {
        if (val) {
            $rootScope.css.accu = val;
            // eslint-disable-next-line no-undef
            globalThis.css.accu = val;
        } else {
            $rootScope.css.accu = $window.accu;
            // eslint-disable-next-line no-undef
            globalThis.css.accu = $window.accu;
        }
    };
    vm.changeStyle = function (key) {
        if ($scope.previousSelectedVal != "none") {
            // eslint-disable-next-line no-undef
            document
                .getElementById($scope.previousSelectedVal)
                .setAttribute("class", "panel-heading form-group inactive");
        }
        // eslint-disable-next-line no-undef
        document.getElementById(key).setAttribute("class", "panel-heading form-group active");
        $scope.previousSelectedVal = key;
    };
    vm.init();
};

PrimaryPlanSelectionCtrl.$inject = [
    "$cookies",
    "$q",
    "$rootScope",
    "$scope",
    "$stateParams",
    "$translate",
    "$window",
    "AccuCodeService",
    "DefaultCustomizationRetrService",
    "PreLoginAccuRetrievalService",
    "PreLoginContactInfoService",
    "PrimaryPlanSelectionFactory",
    "ReSetACCUService",
    "eventBus",
    "redirectService",
    "TransactionAccessRetrService"
];
export default PrimaryPlanSelectionCtrl;
