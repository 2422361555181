import ReferenceData from "../../constants/ReferenceData";
import { executePostWithPcapAuth } from "../../utils/appUtils";
import localBuildConfig from "../../../../../../build/buildConfig.json";

interface AuthData {
    SP_HEADER_VERSION: String;
    authLevel: String;
    status: String;
    success: String;
    userGuid: String;
    username: String;
}
interface AuthenticateArgs {
    csrfToken: string;
    isLoginHelp?: boolean | undefined;
}

export const multiAuth = localBuildConfig.multiAuth;
export const currentPcBaseApiUrl = multiAuth[window.subdomain];
const challengePhone = async ({ csrfToken, isLoginHelp }: AuthenticateArgs): Promise<AuthData> => {
    const payload = {
        challengeReason: isLoginHelp ? "PWD_RESET" : "DEVICE_AUTH",
        challengeMethod: "OP",
        apiClient: "WEB",
        bindDevice: false,
        csrf: csrfToken
    };

    const liveUrl = currentPcBaseApiUrl + ReferenceData.CALL_CHALLENGE_PHONE_AUTH;
    const result = await executePostWithPcapAuth(liveUrl, "callChallengePhoneAuthFault", payload);
    return result.data.spHeader;
};

export default challengePhone;
