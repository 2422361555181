import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslations } from "../../queries";
import { NoContactInfoModalProps, LinkablePlansTranslations } from "./types";

const NoContactInfoModal = ({ plansFromCurrentContext, setShowModal }: NoContactInfoModalProps) => {
    const cancelModal = () => {
        setShowModal(false);
    };

    const { oneid } = useTranslations<LinkablePlansTranslations>();

    return (
        <Modal>
            <div
                className="modal-content"
                role="dialog"
                aria-hidden="false"
                data-ng-init="LinkablePlansSelectionController.dataForNoContactInfoModal()
        "
            >
                <Modal.Header>
                    {plansFromCurrentContext.map((planValue) => (
                        <div key={planValue.planName} className="block-title-linking">
                            <b>{planValue.planName}</b>
                        </div>
                    ))}
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-well with-background">
                        {plansFromCurrentContext.length === 1 && (
                            <p>
                                {oneid.noContactInfoModal.intro.replace(
                                    "{{phoneNum}}",
                                    plansFromCurrentContext[0].planContactPhoneNumber
                                )}
                            </p>
                        )}
                        {plansFromCurrentContext.length > 1 && (
                            <p>
                                {oneid.noContactInfoModal.introWithMorePlans.replace(
                                    "{{phoneNum}}",
                                    plansFromCurrentContext[0].planContactPhoneNumber
                                )}
                            </p>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-primary margin-top-default oneid-button-large"
                        onClick={cancelModal}
                    >
                        {oneid.noContactInfoModal.ok}
                    </button>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default NoContactInfoModal;
