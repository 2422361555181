import AccessibilityUtil from "core-ui/client/src/app/core/util/AccessibilityUtil";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import dateUtil from "core-ui/client/src/app/DateUtil";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import MaskUtil from "core-ui/client/src/app/MaskUtil";
import _isNumber from "lodash/isNumber";
import _isUndefined from "lodash/isUndefined";
import _keys from "lodash/keys";
import _mapValues from "lodash/mapValues";
import _pull from "lodash/pull";

import IDProofConsentController from ".././idProof/IDProofConsentController";
import idProofConsentTemplate from "../idProof/idProofConsentTemplate";

import registrationEvents from "./events/RegistrationEvents";
import EventBusEvents from "../login/events/EventBusEvents";
import newRegistrationEvents from "./events/registration";

const registrationController = function (
    $injector,
    $modal,
    $cookies,
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $timeout,
    $translate,
    AccuCodeService,
    AuthenticationStatusService,
    LogoutService,
    MenuNavigationFactory,
    SetACCUService,
    eventBus,
    redirectService,
    registrationFactory
) {
    let previousSSN;
    let newSSN;
    let previousDateOfBirth;

    const logger = ExternalLogger.getInstance("registrationController");
    $rootScope.iframeUrl = globalThis.iframeUrl;
    $scope.accountSetUp.fromLogin = false;
    $scope.ssnError = "";
    $scope.zipCodeError = "";
    $scope.uniqueIdentifierError = "";
    $scope.planIDError = "";
    $scope.ssnDateOfBirthError = "";
    $scope.uniqueidDateOfBirthError = "";
    $scope.streetAddressError = "";
    $scope.autherizationMessage = "";
    $scope.autherizationMessageParams = "";
    $scope.areFieldsRequired = {
        ssnFields: true,
        uniqueidFields: true
    };
    $scope.individual = {
        identifierType: "ssn",
        ssn: "",
        zipCode: "",
        uniqueIdentifier: "",
        planID: "",
        ssnLastName: "",
        ssnDateOfBirth: "",
        uniqueidLastName: "",
        uniqueidDateOfBirth: "",
        streetAddress: "",
        pin: ""
    };
    $scope.accu = $cookies.get("accu");
    // CODE BLOCK is for PART-45271
    if ($stateParams.featureName) $rootScope.featureName = $stateParams.featureName;

    const callAmplitudeEvent = function (eventBusPayload) {
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
            selection: registrationEvents.LOGIN_ERROR_CODE,
            payload: eventBusPayload
        });
    };

    const callAmplitudeEventPageView = function (href) {
        let message;
        if (href.includes("/pin")) {
            message = newRegistrationEvents.PGE_VIEW_PIN;
        } else {
            message = newRegistrationEvents.PGE_VIEW_NO_PIN;
        }
        eventBus.dispatch(EventBusEvents.REGISTER);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.VIEW_PAGE,
            event_properties: {
                selection: message
            }
        });
    };

    extractErrorMessageFromRedirection(window.location.href);
    extractIframeURLFromRedirection(window.location.href);
    focusSSNField();

    AuthenticationStatusService.getAuthData().then(function (data) {
        $scope.areFieldsRequired.ssnFields =
            !$rootScope.accuCustomization.showLoginSetupRecoveryRadio;
        $scope.areFieldsRequired.uniqueidFields =
            $rootScope.accuCustomization.showLoginSetupRecoveryRadio;
        $scope.individual.identifierType = checkIdentifierType();

        function propertyToBoolean(val) {
            const booleanState = ["false", "true"].indexOf(val);
            return booleanState >= 0 ? Boolean(booleanState) : val;
        }
        const authStatus = _mapValues(data, propertyToBoolean);
        if (authStatus.authenticated) {
            LogoutService.logOutServices.query(function () {
                LogoutService.logOutPartAuth.query().$promise.then(
                    {},
                    function () {
                        console.log("part auth session invalidated");
                        $cookies.remove("JSESSIONID", { path: "/participant-web-services" });
                        $cookies.remove("JSESSIONID", { path: "/participant-authentication" });
                        $cookies.remove("site_tok", { path: "/participant" });
                        $cookies.remove("site_tok", { path: "/" });
                        $cookies.remove("X-CSRF-TOKEN", { path: "/" });
                        $cookies.remove("PM-INDID-TOKEN", { path: "/" });
                        $cookies.remove("PM-PLAN-TOKEN", { path: "/" });
                    },
                    function () {
                        $cookies.remove("JSESSIONID", { path: "/participant-web-services" });
                        $cookies.remove("JSESSIONID", { path: "/participant-authentication" });
                        $cookies.remove("site_tok", { path: "/participant" });
                        $cookies.remove("site_tok", { path: "/" });
                        $cookies.remove("X-CSRF-TOKEN", { path: "/" });
                        $cookies.remove("PM-INDID-TOKEN", { path: "/" });
                        $cookies.remove("PM-PLAN-TOKEN", { path: "/" });
                    }
                );
                MenuNavigationFactory.getAccu.query({}, function (code) {
                    //using image rather than $http due to redirects.
                    const tmpImg = new Image();
                    if (code[0] === $scope.accu) {
                        // eslint-disable-next-line no-undef
                        tmpImg.src = __iframeUrl__ + "/logoutNextgen.do?accu=" + $scope.accu;
                    } else {
                        // eslint-disable-next-line no-undef
                        tmpImg.src =
                            // eslint-disable-next-line no-undef
                            __iframeUrl__ +
                            "/logoutNextgen.do?accu=" +
                            code[0] +
                            "&overrideAccu=" +
                            $scope.accu;
                    }
                    $rootScope.$emit("loginAuthStatusVerified", $scope.authenticationStatus);
                });

                // Since the above logout is clearing out the PWS session with the accu codes which were being set originally when the page being loaded.

                const accuParam = AccuCodeService.getAccuCode();
                $scope.accu = accuParam;

                logger.info("accu is [{0}]", [accuParam]);

                SetACCUService.query({ accu: accuParam }).$promise.then(function () {});
            });
        } else {
            $rootScope.$emit("loginAuthStatusVerified", $scope.authenticationStatus);
        }
    });
    $scope.content = {
        idProofingEnabled: $translate.instant("idProofing.enabled")
    };
    if (!$scope.content.idProofingEnabled) {
        $scope.content.idProofingEnabled = false;
    }
    $scope.includes = $state.includes;
    $scope.accordion = {
        isWithoutPinOpen: true,
        isWithPinOpen: true,
        isGroupOpen: true
    };
    $scope.goToTab = function (tab) {
        $state.go("registration." + tab);
    };
    $scope.registrationWithPin = true;
    $scope.registrationWithoutPin = true;
    $scope.registrationWithGroupPassword = true;
    $scope.loginURL = {
        URL: null,
        HEADER: null
    };

    if ($stateParams.hardStopError) {
        getLoginURL();
        $cookies.remove("accu", { path: "/participant" });
    }

    getDevice();

    $scope.accordion = {
        isWithoutPinOpen: true,
        isWithPinOpen: true,
        isGroupOpen: true
    };

    $scope.cleaveoptions = {
        ssn: {
            blocks: [3, 2, 4],
            delimiters: ["-"]
        }
    };
    /**
     * focus manager
     * indexed by fieldName
     * @type {{}}
     */
    $scope.focusMgr = {};
    function getLoginURL() {
        $scope.loginURL = redirectService.getLoginURL();
    }
    function focusSSNField() {
        $timeout(function () {
            callAmplitudeEventPageView(window.location.href);
            const ssnField = document.getElementById("ssn");
            if (ssnField) {
                ssnField.focus();
            }
        }, 0);
    }
    $scope.includeAcc = function (tabName) {
        if (tabName === "register.withoutPin") {
            $scope.registrationWithoutPin = true;
            $scope.registrationWithPin = false;
        } else if (tabName === "register.withPin") {
            $scope.registrationWithoutPin = false;
            $scope.registrationWithPin = true;
        }
    };

    $scope.fieldOnBlur = function (fieldName) {
        if (_isUndefined($scope.registerForm)) {
            return "";
        }
        const field = $scope.registerForm[fieldName];
        const fieldFocused = $scope.focusMgr[fieldName] || false;
        let status = "";

        if (field.$touched && field.$invalid && !fieldFocused) {
            status = "has-error";
        }
        return status;
    };
    $scope.getFormGroupClass = function (fieldName) {
        const method = "getFormGroupClass(" + fieldName + ") ";
        if (_isUndefined($scope.registerForm)) {
            return "";
        }
        const field = $scope.registerForm[fieldName];
        const fieldFocused = $scope.focusMgr[fieldName] || false;
        let status = "";

        if (field && field.$invalid && field.$dirty && !fieldFocused) {
            status = "has-error";
        }

        if (field && field.$valid && field.$dirty) {
            status = "has-success";
        }
        logger.debug("{0} returning [{1}]", [method, status]);
        return status;
    };
    $scope.isTouched = function (fieldName) {
        return $scope.registerForm[fieldName].$dirty;
    };
    $scope.isDirty = function (fieldName) {
        if (_isUndefined($scope.registerForm)) {
            return false;
        }
        const dirty =
            $scope.registerForm[fieldName] !== undefined && $scope.registerForm[fieldName].$dirty;

        return dirty;
    };
    $scope.displayFieldMessages = function (fieldName, suppressFocused) {
        const method = "displayFieldMessages(" + fieldName + ") ";
        if (_isUndefined(suppressFocused)) {
            suppressFocused = false;
        }

        if (_isUndefined($scope.registerForm)) {
            return "";
        }
        const field = $scope.registerForm[fieldName];
        if (_isUndefined($scope.registerForm) || !field) {
            return true;
        }
        const conditions =
            (field.$invalid && field.$dirty && !suppressFocused) ||
            (field.$touched && !suppressFocused) ||
            (!field.$pristine && !suppressFocused);
        const logData = {
            conditions: conditions,
            $invalid: field.$invalid,
            $dirty: field.$dirty,
            suppressFocused: suppressFocused,
            $touched: field.$touched,
            $pristine: field.$pristine
        };
        logger.debug("{0} response: {1}", [method, JSON.stringify(logData)]);
        return conditions;
    };
    $scope.getFieldError = function (fieldName) {
        const method = "getFieldError(" + fieldName + ") ";
        let rc = null;
        if (
            _isUndefined($scope.registerForm) ||
            !fieldName ||
            _isUndefined($scope.registerForm[fieldName])
        ) {
            logger.debug("getFieldError(" + fieldName + ") .. unknown field.");
            rc = "";
        } else {
            rc = $scope.registerForm[fieldName].$error;
        }
        logger.debug("{0} rc=[{1}]", [method, JSON.stringify(rc)]);
        return rc;
    };
    $scope.removeInputFocus = function (event) {
        $timeout(function () {
            $scope.focusMgr[event.target.name] = false;
        });
    };

    $scope.setInputFocus = function (event) {
        $scope.focusMgr[event.target.name] = true;
        $scope.registerForm[event.target.name].$setTouched(true);
        $scope.registerForm[event.target.name].$setDirty(true);
    };
    // **********
    function getDevice() {
        const isMobile = {
            Android: function () {
                return navigator.userAgent.match(/Android/i);
            },
            BlackBerry: function () {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: function () {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
            Opera: function () {
                return navigator.userAgent.match(/Opera Mini/i);
            },
            Windows: function () {
                return navigator.userAgent.match(/IEMobile/i);
            },
            any: function () {
                return (
                    isMobile.Android() ||
                    isMobile.BlackBerry() ||
                    isMobile.iOS() ||
                    isMobile.Opera() ||
                    isMobile.Windows()
                );
            }
        };

        if (isMobile.any()) {
            $scope.mobileDevice = "true";
        } else {
            $scope.mobileDevice = "false";
        }
    }

    function registerFormIsValid() {
        if ($scope.individual.identifierType === "ssn") {
            return (
                $scope.registerForm.ssn.$valid &&
                $scope.registerForm.zipCode.$valid &&
                $scope.registerForm.ssnDateOfBirth.$valid
            );
        }
        if ($scope.individual.identifierType === "uniqueid") {
            return (
                $scope.registerForm.uniqueIdentifier.$valid &&
                $scope.registerForm.planID.$valid &&
                $scope.registerForm.uniqueidDateOfBirth.$valid
            );
        }
    }

    $scope.registerWithoutPin = function (flowName) {
        eventBus.dispatch(registrationEvents.SUBMIT_NO_PIN, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: registrationEvents.SUBMIT_NO_PIN
            }
        });
        $rootScope.flowName = flowName;
        $scope.individual.identifierType = checkIdentifierType(); // WCDX-9804 set identifierType to $rootscope value if the page is reloaded.

        if (registerFormIsValid()) {
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: newRegistrationEvents.SUCCESSFUL_SUBMIT_NO_PIN
                }
            });
            $scope.showSpinner = true;
            let registerData;
            const baseRegisterData = {
                flowName: flowName,
                isIDProofing: $scope.content.idProofingEnabled,
                accu: $cookies.get("accu")
            };
            if ($scope.individual.identifierType === "ssn") {
                registerData = {
                    lastName: $scope.individual.ssnLastName,
                    dateOfBirth: $scope.individual.ssnDateOfBirth,
                    ssn: newSSN,
                    zipcode: $scope.individual.zipCode,
                    ...baseRegisterData
                };
            }
            if ($scope.individual.identifierType === "uniqueid") {
                registerData = {
                    lastName: $scope.individual.uniqueidLastName,
                    dateOfBirth: $scope.individual.uniqueidDateOfBirth,
                    uniqueId: $scope.individual.uniqueIdentifier,
                    metrixPlanId: $scope.individual.planID,
                    ...baseRegisterData
                };
            }

            registrationFactory.registerWithoutPin.query(
                registerData,

                function (data) {
                    $scope.showSpinner = false;
                    if (data.errors === false) {
                        if (data.state === "ID_PROOFING_CONSENT") {
                            openConsentModal();
                        } else {
                            $rootScope.contactNoForIDProof = data.contactNoForIDProof;
                            $rootScope.flowName = data.flowName;
                            redirectService.redirect(data, "ALL");
                        }
                    }
                },
                //if data.errors===true, it will go with the function below.

                function (response) {
                    $scope.showSpinner = false;

                    $scope.autherizationMessage = "";
                    $scope.ssnError = "";
                    $scope.zipCodeError = "";
                    $scope.ssnDateOfBirthError = "";
                    $scope.uniqueidDateOfBirthError = "";
                    $scope.streetAddressError = "";

                    if (response.headers("exception") !== null) {
                        $scope.autherizationMessage = response.data.error.code;
                        $scope.autherizationMessageParams = response.data.error.errors[0];
                        $scope.authStatus = response.status;

                        if ($scope.authStatus === 500) {
                            $scope.loginurl = $scope.autherizationMessageParams.errorCode;
                            redirectService.setLoginURL(
                                $scope.loginurl,
                                "Registration Error",
                                "registration"
                            );

                            $state.go("registrationHardStopError");
                        } else if (
                            $scope.authStatus === 401 &&
                            response.data !== null &&
                            $scope.autherizationMessage === "alternative.error.login" &&
                            $scope.autherizationMessageParams !== null
                        ) {
                            $scope.autherizationMessage = "logon." + $scope.autherizationMessage;
                            $scope.accuError = {
                                showDialog: true,
                                redirectAccuUrl: sanitizeURLforRedirection(
                                    $scope.autherizationMessageParams.ALTERNATIVE_LOGN_URL
                                )
                            };
                            $scope.redirectAccuSite();
                        }
                        handleScreenReader("screenReaderAuth");
                    } else {
                        if (
                            response.message !== null &&
                            response.message === "Unexpected token T"
                        ) {
                            $scope.autherizationMessage = "Internal System Error";
                        } else if (
                            response.data !== null &&
                            response.data.error.code === "alternative.error.login" &&
                            response.data.error.errors[0] !== null
                        ) {
                            $scope.autherizationMessage = "logon." + response.data.error.code;
                            $scope.accuError = {
                                showDialog: true,
                                redirectAccuUrl: sanitizeURLforRedirection(
                                    response.data.error.errors[0].ALTERNATIVE_LOGN_URL
                                )
                            };
                            $scope.redirectAccuSite();
                        } else if (response.data !== null || response.data.errors === true) {
                            $scope.ssnError = response.data.ssn;
                            $scope.zipCodeError = response.data.zipcode;
                            $scope.streetAddressError = response.data.streetAddress;
                            if ($scope.individual.identifierType === "ssn") {
                                $scope.ssnDateOfBirthError = response.data.dateOfBirth;
                            }
                            if ($scope.individual.identifierType === "uniqueid") {
                                $scope.uniqueidDateOfBirthError = response.data.dateOfBirth;
                            }
                        } else if (response.appleunauthorized !== null) {
                            $state.go("login");
                        }
                    }
                }
            );
        } else {
            // Some input is not valid, so we need to prompt the participant to fix the issue
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: newRegistrationEvents.ATTEMPT_SUBMIT_NO_PIN
                }
            });
            // Manually set the $dirty flag for each input in the form to trigger validation
            if ($scope.individual.identifierType === "ssn") {
                $scope.registerForm.ssnDateOfBirth.$setDirty();
                $scope.registerForm.zipCode.$setDirty();
                $scope.registerForm.zipCode.$setDirty();
            }
            if ($scope.individual.identifierType === "uniqueid") {
                $scope.registerForm.uniqueidDateOfBirth.$setDirty();
                $scope.registerForm.uniqueIdentifier.$setDirty();
                $scope.registerForm.planID.$setDirty();
                $scope.registerForm.uniqueIdentifier.$setDirty();
                $scope.registerForm.planID.$setDirty();
            }

            if ($scope.registerForm.ssn) {
                $scope.registerForm.ssn.$setDirty();
            } else {
                $scope.registerForm.ssnM.$setDirty();
            }

            // Handle screen reader accessibility
            handleScreenReader("screenReader");
        }
    };
    // focus the error messages
    const handleScreenReader = function (id) {
        AccessibilityUtil.focusElement(id);
    };
    // focus the input with an error
    $scope.focusOnErrorInput = function (e, elementId) {
        AccessibilityUtil.focusOnErrorInput(e, elementId);
    };
    // focus on the first error in the screen reader error block
    $scope.enableScreenReaderErrors = function (e) {
        AccessibilityUtil.enableScreenReaderErrors(e);
    };
    $scope.registerWithPin = function () {
        eventBus.dispatch(registrationEvents.SUBMIT_PIN, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: registrationEvents.SUBMIT_PIN
            }
        });
        // Check if the form input is valid
        if ($scope.registerForm.$valid) {
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: newRegistrationEvents.SUCCESSFUL_SUBMIT_PIN
                }
            });
            $scope.showSpinner = true;
            // Everything is good, we can continue with registration
            $rootScope.flowName = "registrationFlow";
            const registerData = {
                ssn: newSSN,
                pin: $scope.individual.pin,
                flowName: $rootScope.flowName,
                isIDProofing: $scope.content.idProofingEnabled,
                accu: $cookies.get("accu")
            };

            registrationFactory.registerWithPin.query(
                registerData,

                function (data) {
                    $scope.showSpinner = false;

                    if (data.errors === false) {
                        redirectService.redirect(data, "ALL");
                    }
                },
                function (response) {
                    $scope.showSpinner = false;
                    $scope.autherizationMessage = "";
                    $scope.autherizationMessageParams = "";
                    $scope.ssnError = "";
                    $scope.pinCodeError = "";
                    if (response.headers("exception") !== null) {
                        $scope.autherizationMessage = response.data.error.code;
                        $scope.autherizationMessageParams = response.data.error.errors[0];
                        $scope.authStatus = response.status;
                        if ($scope.authStatus === 500) {
                            $scope.loginurl = $scope.autherizationMessageParams.errorCode;
                            redirectService.setLoginURL(
                                $scope.loginurl,
                                "Registration Error",
                                "registration"
                            );
                            $state.go("registrationHardStopError");
                        } else if (
                            $scope.authStatus === 401 &&
                            response.data !== null &&
                            $scope.autherizationMessage === "alternative.error.login" &&
                            $scope.autherizationMessageParams !== null
                        ) {
                            $scope.autherizationMessage = "logon." + $scope.autherizationMessage;
                            $scope.accuError = {
                                showDialog: true,
                                redirectAccuUrl: sanitizeURLforRedirection(
                                    $scope.autherizationMessageParams.ALTERNATIVE_LOGN_URL
                                )
                            };
                            $scope.redirectAccuSite();
                        }
                        handleScreenReader("screenReaderAuth");
                    } else {
                        if (
                            response.message !== null &&
                            response.message === "Unexpected token T"
                        ) {
                            $scope.autherizationMessage = "Internal System Error";
                        } else if (
                            response.data !== null &&
                            response.data.error.code === "alternative.error.login" &&
                            response.data.error.errors[0] !== null
                        ) {
                            $scope.autherizationMessage = "logon." + response.data.error.code;
                            $scope.accuError = {
                                showDialog: true,
                                redirectAccuUrl: sanitizeURLforRedirection(
                                    response.data.error.errors[0].ALTERNATIVE_LOGN_URL
                                )
                            };
                            $scope.redirectAccuSite();
                        } else if (response.data !== null) {
                            $scope.ssnError = response.data.ssn;
                            $scope.pinError = response.data.pin;
                        }
                    }
                }
            );
        } else {
            // Some input is not valid, so we need to prompt the participant to fix the issue
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: newRegistrationEvents.ATTEMPT_SUBMIT_PIN
                }
            });

            // Manually set the $dirty flag for each input in the form to trigger validation
            $scope.registerForm.pin.$dirty = true;
            if ($scope.mobileDevice === "true") {
                $scope.registerForm.ssnM.$dirty = true;
            } else {
                $scope.registerForm.ssn.$dirty = true;
                $scope.registerForm.ssn.$dirty = true;
            }
            // Handle screen reader accessibility
            handleScreenReader("screenReader");
        }
    };
    $scope.idProofWithPin = function () {
        eventBus.dispatch(registrationEvents.SUBMIT_IDPROOF_PIN, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: registrationEvents.SUBMIT_IDPROOF_PIN
            }
        });
        // Check if the form input is valid
        if ($scope.registerForm.$valid) {
            $scope.showSpinner = true;
            // Everything is good, we can continue with registration
            $rootScope.flowName = "registrationFlow";
            const registerData = {
                pin: $scope.individual.pin,
                flowName: $rootScope.flowName,
                accu: $cookies.get("accu"),
                isIDProofing: $scope.content.idProofingEnabled
            };

            registrationFactory.idProofPinAPi.query(
                registerData,

                function (data) {
                    $scope.showSpinner = false;

                    if (data.errors === false) {
                        redirectService.redirect(data, "ALL");
                    }
                },
                function (response) {
                    $scope.showSpinner = false;
                    $scope.autherizationMessage = "";
                    $scope.autherizationMessageParams = "";
                    $scope.ssnError = "";
                    $scope.pinCodeError = "";
                    if (response.headers("exception") !== null) {
                        $scope.autherizationMessage = response.data.error.code;
                        $scope.autherizationMessageParams = response.data.error.errors[0];
                        $scope.authStatus = response.status;
                        if ($scope.authStatus === 500) {
                            $scope.loginurl = $scope.autherizationMessageParams.errorCode;
                            redirectService.setLoginURL(
                                $scope.loginurl,
                                "Registration Error",
                                "registration"
                            );
                            $state.go("registrationHardStopError");
                        }
                        handleScreenReader("screenReaderAuth");
                    } else {
                        if (
                            response.message !== null &&
                            response.message === "Unexpected token T"
                        ) {
                            $scope.autherizationMessage = "Internal System Error";
                        } else if (response.data !== null) {
                            $scope.pinError = response.data.pin;
                        }
                    }
                }
            );
        } else {
            // Some input is not valid, so we need to prompt the participant to fix the issue

            // Manually set the $dirty flag for each input in the form to trigger validation
            $scope.registerForm.pin.$dirty = true;
            if ($scope.mobileDevice === "true") {
                $scope.registerForm.ssnM.$dirty = true;
            } else {
                $scope.registerForm.ssn.$dirty = true;
                $scope.registerForm.ssn.$dirty = true;
            }
            // Handle screen reader accessibility
            handleScreenReader("screenReader");
        }
    };

    const checkIdentifierType = function () {
        return $rootScope.accuCustomization.showLoginSetupRecoveryRadio ? "uniqueid" : "ssn";
    };
    const openConsentModal = function () {
        $modal.open({
            template: idProofConsentTemplate,
            controller: IDProofConsentController,
            controllerAs: "idProofConsentCtrl",
            size: "md",
            windowClass: "modal-wrapper"
        });
    };
    const otpService = $injector.get("otpService");

    $scope.openOTPNoCodeModalModal = otpService.openOTPNoCodeModalModal;
    $scope.handleFocusInput = function (event) {
        event.currentTarget.type = "text";
    };
    $scope.handleBlurInput = function (event) {
        event.currentTarget.type = "password";
    };
    $scope.identifierTypeChange = function () {
        eventBus.dispatch(registrationEvents.ID_TYPE_CHANGE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: registrationEvents.ID_TYPE_CHANGE
            }
        });
    };
    $scope.ssnInputChange = function () {
        eventBus.dispatch(registrationEvents.SSN_CHANGE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: registrationEvents.SSN_CHANGE
            }
        });
    };
    $scope.zipChange = function () {
        eventBus.dispatch(registrationEvents.ZIP_CHANGE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_EVENT,
            event_properties: {
                selection: registrationEvents.ZIP_CHANGE
            }
        });
    };
    $scope.lastNameChange = function () {
        eventBus.dispatch(registrationEvents.LAST_NAME_CHANGE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: registrationEvents.LAST_NAME_CHANGE
            }
        });
    };
    $scope.dobChange = function () {
        eventBus.dispatch(registrationEvents.DOB_CHANGE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: registrationEvents.DOB_CHANGE
            }
        });
    };
    $scope.uniqueIdentifierChange = function () {
        eventBus.dispatch(registrationEvents.UNIQUE_ID_CHANGE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: registrationEvents.UNIQUE_ID_CHANGE
            }
        });
    };
    $scope.uniqueIdentifierTooltipSelect = function () {
        eventBus.dispatch(registrationEvents.UNIQUE_ID_TOOLTIP_SELECT, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_EVENT,
            event_properties: {
                selection: registrationEvents.UNIQUE_ID_TOOLTIP_SELECT
            }
        });
    };
    $scope.planIDChange = function () {
        eventBus.dispatch(registrationEvents.PLAN_ID_CHANGE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: registrationEvents.PLAN_ID_CHANGE
            }
        });
    };
    $scope.streetChange = function () {
        eventBus.dispatch(registrationEvents.STREET_CHANGE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: registrationEvents.STREET_CHANGE
            }
        });
    };
    $scope.submitClick = function (source) {
        eventBus.dispatch(registrationEvents.SUBMIT, this, {
            source: source
        });
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: registrationEvents.SUBMIT,
                source
            }
        });
    };
    $scope.registerNoPin = function (source) {
        eventBus.dispatch(registrationEvents.REGISTRATION_NO_PIN, this, {
            source: source
        });
    };
    $scope.registerPin = function (source) {
        eventBus.dispatch(registrationEvents.REGISTRATION_PIN, this, {
            source: source
        });
    };
    $scope.registerGroup = function (source) {
        eventBus.dispatch(registrationEvents.REGISTRATION_GROUP, this, {
            source: source
        });
    };
    $scope.handlePlanEnrollmentCodeSubmit = function () {
        eventBus.dispatch(registrationEvents.SUBMIT_PLAN_ENROLLMENT_CODE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: registrationEvents.SUBMIT_PLAN_ENROLLMENT_CODE
            }
        });
    };
    $scope.ssnChangePin = function () {
        eventBus.dispatch(registrationEvents.SSN_CHANGE_PIN, this);
    };
    $scope.pinChange = function () {
        eventBus.dispatch(registrationEvents.PIN_CHANGE, this);
    };
    $scope.getMinimumDateOfBirth = function () {
        return dateUtil.getMinimumDateOfBirth();
    };
    /**
     * determine the validation error (style) class.
     * different class if one error vs multiples.
     */
    $scope.getValidationErrorClass = function (fieldName) {
        const method = "getValidationErrorClass(" + fieldName + ") ";

        logger.debug("{0} starting", [method]);
        let styleName = "rule-validations";

        if (
            _isUndefined($scope.registerForm) ||
            !fieldName ||
            _isUndefined($scope.registerForm[fieldName])
        ) {
            logger.debug("{0} form element not found. returning [{1}]", [method, styleName]);
            return styleName;
        }

        const errs = $scope.registerForm[fieldName].$error;
        if (errs) {
            logger.debug("{0} errs={1}", [method, JSON.stringify(errs)]);

            const errorKeys = _keys(errs);
            logger.debug("{0} errorKeys={1}", [method, JSON.stringify(errorKeys)]);

            //special case, no messaging is defined for pin "pattern" errors.
            //so removing it from the list to avoid false ">1" logic check.
            if (fieldName === "pin") {
                _pull(errorKeys, "pattern");
            }

            const errorCnt = errorKeys.length;
            logger.debug("{0} errCnt={1}", [method, errorCnt]);

            //if >1 errors, the style class should be bulleted.
            if (errorCnt > 1) {
                styleName = "form-validation-rule";
            }

            logger.debug("{0} returning [{2}]", [method, styleName]);
        }

        return styleName;
    };
    $scope.redirectAccuSite = function () {
        $scope.dialogAccuErrorTimer = $timeout(function () {
            $scope.accuError.showDialog = false;
            window.location.replace($scope.accuError.redirectAccuUrl);
        }, 30000);
    };
    $scope.handleMaskSSN = (event) => {
        const method = "handleMaskSSN()";
        const SSN = $scope.registerForm.ssn.$viewValue;
        const SSNPattern = /^\d{3}-\d{2}-\d{4}/;

        logger.debug("{0} - invoked.  event={1}", [method, event ? event.type : "undefined"]);

        if (SSN === "" && !_isUndefined(previousSSN)) {
            if (MaskUtil.isMasked(previousSSN)) {
                $scope.individual.ssn = previousSSN;
                newSSN = null;
            } else {
                $scope.individual.ssn = MaskUtil.maskSSN(previousSSN);
            }
        }

        if (SSNPattern.test(SSN)) {
            newSSN = SSN.replace(/-/g, "");
            $scope.individual.ssn = MaskUtil.maskSSN(SSN);
        } else {
            logger.debug("{0} - ssn is not valid pattern", [method]);
        }

        logger.debug("{0} - exiting handleMaskSSN", [method]);
    };
    $scope.toggleSSNfromMaskedClick = (event) => {
        const method = "toggleSSNfromMaskedClick()";

        logger.debug("{0} - invoked.  event={1}", [method, event ? event.type : "undefined"]);

        if (!event) {
            logger.debug("{0} - no event.", [method]);
            return;
        }

        if (event.type === "touchend") {
            logger.debug("{0} - ignoring superfluous 'touchend'.", [method]);
            return;
        }

        $scope.focusInput(event);

        if ($scope.isSSNMasked($scope.individual.ssn)) {
            previousSSN = newSSN ? newSSN : $scope.individual.ssn;
            $scope.individual.ssn = "";
        }
    };
    $scope.focusInput = (event) => {
        const method = "focusInput()";

        logger.debug("{0} - invoked.  event={1}", [method, event ? event.type : "undefined"]);

        $timeout(() => {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }, 500);
    };

    $scope.isSSNMasked = (SSN) => {
        return MaskUtil.isMasked(SSN);
    };

    $scope.toggleSSNfromMaskedKeyDown = (event) => {
        if (!$scope.isNavigationKey(event.keyCode)) {
            if ($scope.isSSNMasked($scope.individual.ssn)) {
                const charCode = String.fromCharCode(event.keyCode);

                previousSSN = newSSN ? newSSN : $scope.individual.ssn;
                $scope.individual.ssn = _isNumber(charCode) ? charCode : "";
            }
        }
    };

    $scope.isNavigationKey = (keyCode) => {
        return keyCode && (keyCode === 9 || keyCode === 16 || (keyCode >= 35 && keyCode <= 40));
    };

    $scope.touchDateOfBirth = (event) => {
        const method = "touchDateOfBirth()";

        logger.debug("{0} - invoked.  event={1}", [method, event ? event.type : "undefined"]);
    };

    $scope.toggleDateOfBirthFromClick = (event) => {
        const method = "toggleDateOfBirthFromClick()";

        logger.debug("{0} - invoked.  event={1}", [method, event ? event.type : "undefined"]);

        if (!event) {
            logger.debug("{0} - no event.", [method]);
            return;
        }
        if (event.type === "touchend") {
            logger.debug("{0} - ignoring superfluous 'touchend'.", [method]);
            return;
        }

        $scope.focusInput(event);
    };

    $scope.handleMaskDateOfBirth = (event) => {
        const method = "handleMaskDateOfBirth()";
        const targetName = event.target.name;
        const dateOfBirth = $scope.registerForm[targetName].$viewValue;

        logger.debug("{0} - invoked.  event={1}", [method, event ? event.type : "undefined"]);

        if (dateOfBirth === "" && !_isUndefined(previousDateOfBirth)) {
            $scope.individual.dateOfBirth = previousDateOfBirth;
            previousDateOfBirth = "";
        }
    };

    $scope.toggleDateOfBirthfromKeyDown = (event) => {
        const dateOfBirth = $scope.registerForm.dateOfBirth.$viewValue;

        if (!$scope.isNavigationKey(event.keyCode)) {
            const charCode = String.fromCharCode(event.keyCode);

            if (
                MaskUtil.isMasked($scope.individual.dateOfBirth) ||
                $scope.registerForm.dateOfBirth.$valid
            ) {
                previousDateOfBirth = dateOfBirth;
                $scope.individual.dateOfBirth = _isNumber(charCode) ? charCode : "";
            }
        }
    };

    function sanitizeURLforRedirection(url) {
        //pattern
        //if the url comes like www.viacomwealth.com then is going to be updated as http://www.viacomwealth.com
        //if the url comes like bbmretirementplan.com then is going to be updated as http://bbmretirementplan.com
        const patternProtocol = new RegExp(/http(s)?:\/\//);
        url = url.trim();
        if (!patternProtocol.test(url)) {
            url = "http://" + url;
        }
        return url;
    }

    /** Set $scope parameters not set for loginHelp for oauth login and react loginHelp when invalid user id or pw entered */
    function extractErrorMessageFromRedirection(href) {
        const currentLocation = href;
        if (currentLocation.indexOf("errorMessage") !== -1) {
            if (String(href).indexOf("noScope") > -1) {
                const errorCode = "error.passcode.incorrect.redirect.help";
                const indx = String(href).indexOf("count=");
                const attemptCount = String(href).substring(indx + 6);
                console.log("This url was redirected from the iframe without any scope...", {
                    errorCode,
                    attemptCount
                });
                $scope.autherizationMessage = errorCode;
                $scope.autherizationMessageParams = {
                    attempts: "1",
                    code: errorCode
                };

                const eventBusPayload = {
                    error_code: errorCode
                };

                callAmplitudeEvent(eventBusPayload);
            } else {
                $scope.autherizationMessage = $rootScope.errorMessage.code;
                $scope.autherizationMessageParams = $rootScope.errorMessage;

                const eventBusPayload = {
                    error_code: $rootScope.errorMessage.code
                };

                callAmplitudeEvent(eventBusPayload);
            }
        }
    }

    // iframe is not initiating GA events for loginHelp and register, so we need to call it manually
    function extractIframeURLFromRedirection(href) {
        const currentLocation = href;
        if (currentLocation.indexOf("iframe") !== -1) {
            if (currentLocation.indexOf("loginHelp") !== -1) {
                eventBus.dispatch(EventBusEvents.FORGOT_USERNAME_PASSWORD);
                eventBus.dispatchAmplitude({
                    event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                    event_properties: {
                        selection: EventBusEvents.FORGOT_USERNAME_PASSWORD
                    }
                });
            }
            if (currentLocation.indexOf("register") !== -1) {
                eventBus.dispatch(EventBusEvents.REGISTER);
                eventBus.dispatchAmplitude({
                    event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                    event_properties: {
                        selection: "CoreEvent.register_button_clicked_event"
                    }
                });
            }
        }
    }
};

registrationController.$inject = [
    "$injector",
    "$modal",
    "$cookies",
    "$rootScope",
    "$scope",
    "$state",
    "$stateParams",
    "$timeout",
    "$translate",
    "AccuCodeService",
    "AuthenticationStatusService",
    "LogoutService",
    "MenuNavigationFactory",
    "SetACCUService",
    "eventBus",
    "redirectService",
    "registrationFactory"
];
export default registrationController;
