import activationCodeDeliveryOptionsTemplate from "./activationCodeDeliveryOptionsTemplate";
import activationCodeEntryTemplate from "./activationCodeEntryTemplate";
import noContactInfoOnFileWithSecurityTemplate from "./noContactInfoOnFileWithSecurityTemplate";

const routeStates = function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise("/mfa/activationCodeDeliveryOptions");

    $stateProvider
        // Delivery options =================================
        .state("redwoodMfa", {
            url: "/redwoodMfa",
            template: "<pwr-landing-redwood-mfa-component></pwr-landing-redwood-mfa-component>",
            controller: "mfaController as ctrl"
        })

        .state("activationCodeDeliveryOptions", {
            url: "/activationCodeDeliveryOptions",
            template: activationCodeDeliveryOptionsTemplate,
            controller: "mfaController as ctrl",
            data: {
                fullHeight: true,
                title: "Enhanced security"
            }
        })
        .state("noContactInfo", {
            url: "/mfa/NO_CONTACT_INFO",
            template: noContactInfoOnFileWithSecurityTemplate,
            data: {
                fullHeight: true,
                title: "Something is missing"
            }
        })
        .state("verifyCode", {
            url: "/verifyCode",
            template: activationCodeEntryTemplate,
            controller: "mfaVerifyCodeController",
            data: {
                fullHeight: true,
                title: "Enhanced security"
            }
        });
};

routeStates.$inject = ["$stateProvider", "$urlRouterProvider"];
export default routeStates;
