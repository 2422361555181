import type { Dispatch } from "react";
import FingerprintUtils from "../../../../../utils/FingerprintUtils.js";
import { callAuthenticatePart } from "../../../../services/authentication/AuthenticationService";
import { AuthActionTypes } from "../../useAuthenticateReducer";
import { AuthAction } from "../../useAuthenticateReducer/useAuthenticateReducer";
const { getFingerprint } = FingerprintUtils;

interface AccessPortalGroup {
    accessPortalList: { [key: string]: null | string }[];
    accu: string;
    pptWebAccuCode: null;
}

interface PrimaryPlan {
    accuCode: null;
    database: string;
    defaultGroupId: null;
    defaultIndId: null;
    definedBenefitPlan: boolean;
    gaId: string;
    indId: string;
    planId: null;
    statusCode: string;
    totalBal: number;
}

export interface AuthData {
    accessPortalGroups: AccessPortalGroup[];
    allAccessPortalGroups: AccessPortalGroup[];
    authProvider?: string;
    biometricAllowed: boolean;
    destination: null;
    destinationUrl: string;
    idToken?: string;
    integrated: boolean;
    languageCode: string;
    liatCode: null;
    primaryPlan: PrimaryPlan | null;
    state: string;
    userRegId: string;
}

interface FingerPrintResponse {
    accu?: string;
    deviceFingerPrint: string;
    flowName?: string;
    hasLiedBrowser?: boolean;
    hasLiedLanguages?: boolean;
    hasLiedOs?: boolean;
    hasLiedResolution?: boolean;
    language?: string;
    password?: string;
    userAgent?: string;
    userName?: string;
}

interface AuthenticateArgs {
    accuCode: string;
    deeplink: {
        featureName: string;
        gaId?: string;
        params: string;
    };
    dispatch: Dispatch<AuthAction>;
    password: string;
    setLoggingInState: (loading: boolean) => void;
    username: string;
}

const authenticatePart = async ({
    accuCode,
    deeplink,
    username,
    password,
    setLoggingInState,
    dispatch
}: AuthenticateArgs): Promise<AuthData> => {
    setLoggingInState(true);

    if (!username || !password) {
        setLoggingInState(false);

        dispatch({ type: AuthActionTypes.INVALID_AUTHENTICATION });
        throw new Error("Credentials are invalid");
    }

    dispatch({ type: AuthActionTypes.PROCESSING_AUTHENTICATION });

    try {
        const fingerPrintResponse: FingerPrintResponse = await getFingerprint(false);
        let flowName = "mfa";
        if (deeplink) {
            flowName = "deepLink";
        }

        fingerPrintResponse.userName = username;
        fingerPrintResponse.password = password;
        fingerPrintResponse.flowName = flowName;
        fingerPrintResponse.accu = accuCode;

        const authResponse: AuthData = await callAuthenticatePart(fingerPrintResponse);
        return authResponse;
    } catch (error) {
        console.error("getFingerprint error", error);
        throw error;
    }
};

export default authenticatePart;
