import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../../queries/constants";
import { retrieveContactDetails } from "../../services/accountSetup/accountSetupService";

const useContactDetails = (isNpdiFlow) => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_DETAILS, isNpdiFlow],
        queryFn: () => retrieveContactDetails(isNpdiFlow)
    });
};

export default useContactDetails;
