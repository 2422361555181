import React from "react";
import AccountRecoveryErrorLabel from "./AccountRecoveryErrorLabel";
import { useTranslations } from "../../queries";

interface ConfirmPasswordErrorMessagesProps {
    errorsBag: object;
    label: string;
    wasFocus?: boolean;
}

interface TranslationsConfirmPassword {
    confirmPasswordNotMatch: string;
    confirmPasswordRequired: string;
}

const ConfirmPasswordErrorMessages = ({
    label,
    errorsBag,
    wasFocus
}: ConfirmPasswordErrorMessagesProps) => {
    const { confirmPasswordRequired, confirmPasswordNotMatch } =
        useTranslations<TranslationsConfirmPassword>();
    const errorBag = errorsBag[label];
    if (!errorBag) {
        return "";
    }
    return (
        <div className={`rule-validations ${wasFocus ? "rule-validations-danger" : ""}`}>
            <AccountRecoveryErrorLabel
                error={confirmPasswordRequired}
                completed={errorBag.required}
            />
            <AccountRecoveryErrorLabel error={confirmPasswordNotMatch} completed={errorBag.match} />
        </div>
    );
};

export default ConfirmPasswordErrorMessages;
