import React from "react";

import useAccountRecovery from "./useAccountRecovery";
import AccountRecoveryErrorMessages from "./AccountRecoveryErrorMessages";
import PasswordErrorMessages from "./PasswordErrorMessages";
import ConfirmPasswordErrorMessages from "./ConfirmPasswordErrorMessages";
import useRedirect from "../../hooks/useRedirect";
/**
 * Angular to react version
 * @param {*} param0
 * @returns
 */

interface AccountRecoveryMultipleUserProps {
    redirectService: {
        redirect: (data: { destinationUrl?: string; state: string }, key: string) => void;
    };
}

const AccountRecoveryMultipleUserUpd = ({ redirectService }: AccountRecoveryMultipleUserProps) => {
    let redirect: any = useRedirect();
    if (redirectService) {
        redirect = redirectService.redirect;
    }
    const {
        translations,
        passwordReset,
        userInfo,
        errorsBag,
        resetStatus,
        confirmPasswordError,
        passwordError,
        formValidations,
        pwdChangedUserName,
        currentWorkingUserName,
        returnClick,
        submitForm,
        updateFormInput,
        passwordHelpClick,
        cancelPassword
    } = useAccountRecovery({ mutltipleUserFlow: true, redirect });

    const showBlockingView = false; // TBD
    const isBlockingLoaded = true; // TBD

    const blockingClass = !showBlockingView ? "with-top-marging" : "";
    const isBlockingLoadedClass = !isBlockingLoaded ? "hide" : "";

    return (
        <div
            id="mainBody"
            className={`container with-breadcrumb with-top-margin ${blockingClass} ${isBlockingLoadedClass}`}
        >
            <div className="container row">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
                        <header className="contained-form-header margin-top-default">
                            {passwordReset ? (
                                <h1 data-show="passwordReset">
                                    {translations?.resetflow?.resettitle}
                                </h1>
                            ) : (
                                <h1 data-hide="passwordReset">
                                    {translations?.resetflow?.foundusernames}
                                </h1>
                            )}
                        </header>
                        <div className="inner-container with-padding with-shadow">
                            {resetStatus && (
                                <div className="form-group has-error">
                                    <span className="help-block">{resetStatus}</span>
                                </div>
                            )}
                            {formValidations.isSubmited && !formValidations.isValid && (
                                <AccountRecoveryErrorMessages
                                    errorsLength={formValidations.errorsLength}
                                />
                            )}
                            <form
                                id="accountRecoveryForm"
                                name="accountRecoveryForm"
                                noValidate
                                autoComplete="off"
                                onSubmit={(event) => submitForm(event)}
                            >
                                {!passwordReset &&
                                    userInfo?.username &&
                                    userInfo.userNames?.map((userName, index) => (
                                        <div
                                            className="form-group"
                                            data-hide="passwordReset"
                                            key={userName}
                                        >
                                            <label htmlFor="acUsernameInput">
                                                {translations?.username}&nbsp;{index + 1}
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control form-disabled"
                                                title={translations?.username}
                                                id="acUsernameInput"
                                                name="usernameInput"
                                                value={userName}
                                                readOnly
                                            />

                                            {pwdChangedUserName != null &&
                                                pwdChangedUserName === userName && (
                                                    <div className="form-group" id="passwordReset">
                                                        <p>
                                                            <i className="em-checkbox-icon"></i>{" "}
                                                            {
                                                                translations?.resetflow
                                                                    .passwordchanged
                                                            }{" "}
                                                        </p>
                                                    </div>
                                                )}

                                            {!(
                                                pwdChangedUserName != null &&
                                                pwdChangedUserName === userName
                                            ) && (
                                                <div className="form-group" id="passwordReset">
                                                    <a
                                                        href="#/account-recovery-multipleUsrs"
                                                        className="reset-password-toggle"
                                                        onClick={() => {
                                                            passwordHelpClick(userName);
                                                        }}
                                                    >
                                                        {translations?.resetflow?.resetpwdLink}
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    ))}

                                {passwordReset ? (
                                    <>
                                        <div
                                            className={
                                                errorsBag["password"] &&
                                                errorsBag["password"]?.isValid === false
                                                    ? "form-group has-error"
                                                    : "form-group"
                                            }
                                        >
                                            <div className="form-group">
                                                <label htmlFor="acUsernameInput">
                                                    {currentWorkingUserName}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-disabled"
                                                    title={translations?.username}
                                                    id="acUsernameInput"
                                                    name="usernameInput"
                                                    value={currentWorkingUserName}
                                                    readOnly
                                                />
                                            </div>
                                            <label
                                                htmlFor="acPasswordInput"
                                                className="control-label"
                                            >
                                                {translations?.password}
                                            </label>
                                            <span className="sr-only" id="passwordRules">
                                                {translations?.passwordMinMax}
                                                {translations?.passwordUppercasePattern}.
                                                {translations?.passwordLowercasePattern}.{" "}
                                                {translations?.passwordNumberPattern}.{" "}
                                                {translations?.passwordSpecialCharPattern}.{" "}
                                                {translations?.passwordNotMatchUsername}.
                                            </span>
                                            <input
                                                type="password"
                                                id="acPasswordInput"
                                                className="form-control password-reset"
                                                name="password"
                                                maxLength={63}
                                                onChange={(event) =>
                                                    updateFormInput("password", event.target.value)
                                                }
                                                value={userInfo.password}
                                                aria-describedby="passwordRules"
                                            />

                                            {errorsBag["password"] &&
                                            errorsBag["password"]?.isValid === true ? (
                                                <p className="success">
                                                    {translations?.passwordAcceptable}
                                                </p>
                                            ) : errorsBag["password"] &&
                                              errorsBag["password"]?.isValid === false ? (
                                                <>
                                                    <PasswordErrorMessages
                                                        label="password"
                                                        errorsBag={errorsBag}
                                                    />
                                                    <span id="passwordError" className="help-block">
                                                        {passwordError}
                                                    </span>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </div>

                                        <div
                                            className={
                                                errorsBag["confirmPassword"] &&
                                                errorsBag["confirmPassword"]?.isValid === false
                                                    ? "form-group has-error"
                                                    : "form-group"
                                            }
                                        >
                                            <label
                                                htmlFor="confirmPasswordInput"
                                                className="control-label"
                                            >
                                                {translations?.reEnterPassword}
                                            </label>
                                            <span className="sr-only" id="confirmPasswordRules">
                                                {translations?.confirmPasswordRequired}.{" "}
                                                {translations?.confirmPasswordNotMatch} .
                                            </span>
                                            <input
                                                type="password"
                                                id="confirmPasswordInput"
                                                name="confirmPassword"
                                                maxLength={63}
                                                className="form-control"
                                                value={userInfo.confirmPassword}
                                                onChange={(event) =>
                                                    updateFormInput(
                                                        "confirmPassword",
                                                        event.target.value
                                                    )
                                                }
                                                aria-describedby="confirmPasswordRules"
                                            />
                                            {errorsBag["confirmPassword"] &&
                                            errorsBag["confirmPassword"]?.isValid === true ? (
                                                <p className="success">
                                                    {translations?.passwordAcceptable}
                                                </p>
                                            ) : errorsBag["confirmPassword"] &&
                                              errorsBag["confirmPassword"]?.isValid === false ? (
                                                <>
                                                    <ConfirmPasswordErrorMessages
                                                        label="confirmPassword"
                                                        errorsBag={errorsBag}
                                                    />
                                                    <span
                                                        id="confirmPasswordError"
                                                        className="help-block"
                                                    >
                                                        {confirmPasswordError}
                                                    </span>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </div>

                                        <div className="row cta investment-button-row">
                                            <a
                                                href="#/account-recovery-multipleUsrs"
                                                data-href-opts="{reload: true}"
                                            >
                                                <button
                                                    className="btn btn-link btn-no-style ng-binding"
                                                    onClick={cancelPassword}
                                                >
                                                    Cancel
                                                </button>
                                            </a>
                                            <button
                                                id="submitButton"
                                                type="submit"
                                                className="btn btn-primary pull-right"
                                            >
                                                {translations?.choosePrimaryPlan?.continuebutton}
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <div className="row cta investment-button-row">
                                        <label htmlFor="acUsernameInput">
                                            {translations?.username}(s)
                                        </label>
                                        {userInfo.username && (
                                            <input
                                                type="text"
                                                className="form-control form-disabled"
                                                title={translations?.username}
                                                id="acUsernameInput"
                                                name="usernameInput"
                                                value={userInfo.username}
                                                readOnly
                                            />
                                        )}
                                        <br />
                                        <a href="#/login">
                                            <button
                                                id="submitButton"
                                                type="submit"
                                                aria-label={translations?.returnToLogin}
                                                className="btn btn-primary pull-right"
                                                onClick={returnClick}
                                            >
                                                {translations?.returnToLogin}
                                            </button>
                                        </a>
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountRecoveryMultipleUserUpd;
