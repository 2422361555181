import ReferenceData from "../../constants/ReferenceData";
import { executePostWithPcapAuth } from "../../utils/appUtils";
import localBuildConfig from "../../../../../../build/buildConfig.json";

interface AccountsMetaData {
    hasAggregated: boolean;
    hasCash: boolean;
    hasCredit: boolean;
    hasInvestment: boolean;
    hasOnUs: boolean;
}
interface AuthData {
    SP_HEADER_VERSION: number;
    accountsMetaData: string[];
    accountsSummary: AccountsMetaData;
    authLevel: string;
    betaTester: boolean;
    developer: boolean;
    isDelegate: boolean;
    personId: number;
    qualifiedLead: boolean;
    status: string;
    success: boolean;
    userGuid: string;
    userStage: string;
    username: string;
}
interface AuthenticateArgs {
    csrfToken: string;
    isLoginHelp?: boolean | undefined;
    smsCode: string;
}

export const multiAuth = localBuildConfig.multiAuth;
export const currentPcBaseApiUrl = multiAuth[window.subdomain];

const authenticateSms = async ({
    csrfToken,
    isLoginHelp,
    smsCode
}: AuthenticateArgs): Promise<AuthData> => {
    const payload = {
        code: smsCode,
        challengeReason: isLoginHelp ? "PWD_RESET" : "DEVICE_AUTH",
        challengeMethod: "OP",
        apiClient: "WEB",
        bindDevice: false,
        csrf: csrfToken
    };
    const liveUrl = currentPcBaseApiUrl + ReferenceData.CALL_AUTHENTICATE_SMS_AUTH;
    const result = await executePostWithPcapAuth(liveUrl, "callAuthenticateSmsAuthFault", payload);
    return result.data.spHeader;
};

export default authenticateSms;
