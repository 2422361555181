import React, { useState } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { forEach as _forEach, find as _find, isEmpty as _isEmpty } from "lodash";
import { useTranslations } from "../../queries";
import { PlanValues, LinkablePlansTranslations } from "./types";
import useLinkablePlans from "../../queries/useLinkablePlans/useLinkablePlans";
import useLinkablePlansProfile from "../../queries/useLinkablePlans/useLinkablePlansProfile";
import { useMutation } from "@tanstack/react-query";
import NoContactInfoModal from "./NoContactInfoModal";
import { useNavigate } from "react-router-dom";
import eventBus from "../../../utils/setEventBus";
import oneIdEvents from "../../../../../../client/src/app/oneid/events/OneIdEvents";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import {
    getContextEligibilityForHiding,
    linkAccounts,
    updatePinAuthCode
} from "../../services/oneId/linkablePlansService";
import useRedirect from "../../hooks/useRedirect";

interface LinkablePlansProps {
    redirectService: {
        redirect: (data: { destinationUrl?: string; state: string }, key: string) => void;
    };
}

const LinkablePlans = ({ redirectService }: LinkablePlansProps) => {
    const { oneid } = useTranslations<LinkablePlansTranslations>();
    let redirect: any = useRedirect();
    if (redirectService) {
        redirect = redirectService.redirect;
    }
    const { data: linkablePlansProfileData, error: linkablePlansProfileError } =
        useLinkablePlansProfile();
    const { data: linkablePlansData, error: linkablePlansError } = useLinkablePlans();

    const [loading, setLoading] = useState(false);
    const [plansMapWithIndIdDbName, setPlansMapWithIndIdDbName] = useState({
        "mock:mock:mock": [
            {
                contactsThere: false,
                mfaFinished: false,
                planContactPhoneNumber: "",
                planName: "",
                checked: false
            }
        ]
    }); // mockPlansMap
    const [currentUserPlans, setCurrentUserPlans] = useState([
        {
            contactsThere: false,
            mfaFinished: false,
            planContactPhoneNumber: "",
            planName: "",
            checked: false
        }
    ]); // mockUserPlans
    const [linkingContext, setLinkingContext] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedIndidsForLinking, setSelectedIndidsForLinking] = useState<string[]>([]);
    const STYLE_ENV = __styleEnv__;

    const pinAuthCodeMutation = useMutation({
        mutationFn: updatePinAuthCode
    });
    const contextEligibilityHidingMutation = useMutation({
        mutationFn: getContextEligibilityForHiding,
        onSuccess: (data) => {
            return data;
        }
    });
    const linkAccountsMutation = useMutation({
        mutationFn: linkAccounts
    });

    let currentAccountEligibleForHiding;
    const navigate = useNavigate();

    const setCheckedAttributeBasedOnMFAFinishedAttribute = () => {
        _forEach(plansMapWithIndIdDbName, function (values: any) {
            _forEach(values, function (value) {
                value.checked = value.mfaFinished;
            });
        });
    };

    const continueClick = async () => {
        setLoading(true);
        eventBus.dispatch(oneIdEvents.CONTINUE_LINKING, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_LINK,
            event_properties: {
                selection: oneIdEvents.CONTINUE_LINKING
            }
        });

        _forEach(plansMapWithIndIdDbName, function (value: any, key) {
            _forEach(value, function (planValue: any) {
                if (planValue.checked && planValue.checked === true) {
                    if (selectedIndidsForLinking.indexOf(key) === -1) {
                        setSelectedIndidsForLinking([...selectedIndidsForLinking, key]);
                    }
                }
            });
        });

        let response = {
            data: {},
            error: ""
        };

        try {
            response = await linkAccountsMutation.mutateAsync(selectedIndidsForLinking);
        } catch (e) {
            console.error(e);
        } finally {
            if (response.data) {
                redirect(response.data, "ALL");
            }
            if (response.error) {
                console.error("LinkablePlansFactory.linkAccounts - ERROR: ", response.error);
            }
            setLoading(false);
        }
    };

    setLoading(true);
    const isLinkablePlansProfile = window.location.href.indexOf("linkable-plans-profile") > -1;
    if (isLinkablePlansProfile) {
        if (linkablePlansProfileData) {
            setPlansMapWithIndIdDbName(linkablePlansProfileData["linkablePlansMap"]);
            setCurrentUserPlans(linkablePlansProfileData["currentUserPlans"]);
            setCheckedAttributeBasedOnMFAFinishedAttribute();
        }
        if (linkablePlansProfileError) {
            console.log(
                "LinkablePlansFactory.retrievePlansAvailableForLinkingForProfile - ERROR: ",
                linkablePlansProfileError
            );
        }
    } else {
        if (linkablePlansData) {
            setPlansMapWithIndIdDbName(linkablePlansData.linkablePlansMap);
            setCurrentUserPlans(linkablePlansData.currentUserPlans);
            setCheckedAttributeBasedOnMFAFinishedAttribute();
            if (_isEmpty(plansMapWithIndIdDbName)) {
                continueClick();
            }
        }
        if (linkablePlansError) {
            console.log(
                "LinkablePlansFactory.retrievePlansAvailableForLinking - ERROR: ",
                linkablePlansError
            );
        }
    }
    setLoading(false);

    const checkIfLockNeeded = (keyToCompare) => {
        let lockNeeded = true;
        let keeplooking = true;

        _forEach(plansMapWithIndIdDbName, (values, key) => {
            if (keeplooking && keyToCompare === key) {
                lockNeeded = !values[0]["mfaFinished"];
                keeplooking = false;
            }
        });
        return lockNeeded;
    };

    async function onVerifyAccountClick(key) {
        try {
            await setCurrentAccountEligibleForHiding(key);
            if (currentAccountEligibleForHiding) {
                verifyAccount(key);
            }
        } catch (error) {
            console.log("LinkablePlansFactory.setCurrentAccountEligibleForHiding - ERROR: ", error);
        }
    }

    const setCurrentAccountEligibleForHiding = async (key) => {
        setLoading(true);
        const [indId, dbName, accuCode] = key.split(":");
        const params = {
            indId,
            dbName,
            accuCode
        };

        let response = {
            isContextEligibleForHiding: false,
            errorCode: ""
        };

        try {
            response = await contextEligibilityHidingMutation.mutateAsync(params);
        } catch (e) {
            console.error(e);
        } finally {
            const isContextEligibleForHiding = response.isContextEligibleForHiding || false;
            const errorCode = response.errorCode;
            currentAccountEligibleForHiding = isContextEligibleForHiding ? key : null;
            setLoading(false);
            if (errorCode) {
                console.log(
                    "LinkablePlansFactory.getContextEligibilityForHiding - ERROR: ",
                    errorCode
                );
            }
        }
    };

    const verifyAccountButtonVisibility = (key) => {
        const isDisplayingAccountEligibleForHidingSection = currentAccountEligibleForHiding === key;
        if (isDisplayingAccountEligibleForHidingSection) {
            return false;
        }
        return checkIfLockNeeded(key);
    };

    const isEligibleForHidingSectionVisibility = (key) => {
        return currentAccountEligibleForHiding === key;
    };

    const verifyAccount = (key) => {
        setLinkingContext(key);
        let keeplooking = true;
        // eslint-disable-next-line no-undef
        _forEach(plansMapWithIndIdDbName, (values, tempKey) => {
            if (key === tempKey && keeplooking) {
                // eslint-disable-next-line no-undef
                _forEach(values, (value) => {
                    const contactThere = value?.contactsThere;
                    const mfaFinished = value?.mfaFinished;
                    if (!contactThere && keeplooking) {
                        plansMapWithIndIdDbName[key][0].checked = false;
                        setShowModal(true);
                        value.checked = false;
                        keeplooking = false;
                    } else if (contactThere && !mfaFinished) {
                        navigate("oneIdMfaActivationCode");
                        keeplooking = false;
                    } else if (contactThere && mfaFinished) {
                        navigate("linkablePlans");
                        keeplooking = false;
                    }
                });
            }
        });
    };

    const hideAccount = (key) => {
        setLoading(true);
        const [indId, dbName, accuCode] = key.split(":");
        const params = {
            indId,
            dbName,
            accuCode
        };

        pinAuthCodeMutation.mutate(params);

        setLoading(false);
    };

    const checkForEnabling = () => {
        return (
            _find(plansMapWithIndIdDbName, (account) => account[0].checked === false) === undefined
        );
    };

    const dataForNoContactInfoModal = () => {
        const plans = [] as PlanValues[];
        // eslint-disable-next-line no-undef
        _forEach(plansMapWithIndIdDbName, function (values, key) {
            if (key === linkingContext) {
                // eslint-disable-next-line no-undef
                _forEach(values, function (value: PlanValues) {
                    // console.log('value')
                    plans.push(value);
                });
            }
        });
        return plans;
    };

    const plansFromCurrentContext = dataForNoContactInfoModal();

    const linkablePlansPopover = (
        <Popover id="linkable-plans-tooltip">
            <ul>
                <li>{oneid.linkablePlans.t1}</li>
                <br />
                <li>{oneid.linkablePlans.t2}</li>
            </ul>
        </Popover>
    );

    if (loading) {
        return (
            <div id="statementsDocs-spinner" className="loaderBackground just-element">
                <div className="loader"></div>
            </div>
        );
    }

    return (
        <React.Fragment>
            {showModal && (
                <NoContactInfoModal
                    setShowModal={setShowModal}
                    plansFromCurrentContext={plansFromCurrentContext}
                />
            )}
            <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 account-selection linkable-plans-template">
                <header className="contained-form-header margin-top-default">
                    <h1>{oneid.linkablePlans.header}</h1>
                    <p> {oneid.linkablePlans.intro} </p>
                </header>
                <form
                    className="with-padding with-top-margin margin-bottom-200"
                    name="primaryPlanSelectionForm"
                    noValidate
                >
                    <div className="inner-container with-padding with-shadow clearfix">
                        {currentUserPlans && (
                            <div className="current-user-plans">
                                <div className="flex-heading ng-scope">
                                    {currentUserPlans.length > 1 && (
                                        <label className="control-label">
                                            {oneid.linkablePlans.thesePlans}
                                        </label>
                                    )}
                                    {currentUserPlans.length == 1 && (
                                        <label className="control-label">
                                            {oneid.linkablePlans.thisPlan}
                                        </label>
                                    )}
                                    <OverlayTrigger
                                        trigger={["click", "focus"]}
                                        placement="right"
                                        overlay={linkablePlansPopover}
                                    >
                                        <button>{oneid.linkablePlans.learnMore}</button>
                                    </OverlayTrigger>
                                </div>
                                <div className="well">
                                    <div className="form-group checkbox ng-scope text-center">
                                        <label>
                                            {currentUserPlans.map((planValue) => {
                                                return (
                                                    <span
                                                        key={planValue.planName}
                                                        style={{
                                                            fontSize: "16px",
                                                            whiteSpace: "normal"
                                                        }}
                                                    >
                                                        {planValue.planName}
                                                    </span>
                                                );
                                            })}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}
                        {plansMapWithIndIdDbName && (
                            <div>
                                <label className="control-label">
                                    {oneid.linkablePlans.selectOtherPlans}
                                </label>
                                <div className="well well--blue">
                                    <span className="em-info-icon"></span>
                                    <span>
                                        {oneid.linkablePlans.lockInfo1}
                                        <img
                                            alt="padlock"
                                            src={
                                                STYLE_ENV +
                                                "customizations/Default/images/padlock.svg"
                                            }
                                        />
                                        {oneid.linkablePlans.lockInfo2}
                                    </span>
                                </div>
                                {Object.entries(plansMapWithIndIdDbName).map(([key, value]) => {
                                    return (
                                        <div key={key} className="well well--outline">
                                            <div>
                                                {checkIfLockNeeded(key) && (
                                                    <img
                                                        alt="padlock"
                                                        src={
                                                            STYLE_ENV +
                                                            "customizations/Default/images/padlock.svg"
                                                        }
                                                        className="oneid-lock"
                                                    />
                                                )}
                                                {!checkIfLockNeeded(key) && (
                                                    <img
                                                        alt="checkmark"
                                                        src={
                                                            STYLE_ENV +
                                                            "customizations/Default/images/login/checkmark.svg"
                                                        }
                                                    />
                                                )}
                                                <div>
                                                    {value.map((planValue) => {
                                                        return (
                                                            <div key={key + planValue.planName}>
                                                                {planValue.planName}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                {verifyAccountButtonVisibility(key) && (
                                                    <button
                                                        className="btn btn-primary btn-primary--outline"
                                                        onClick={() => onVerifyAccountClick(key)}
                                                    >
                                                        {oneid.linkablePlans.verifyThisAccount}
                                                    </button>
                                                )}
                                            </div>
                                            {isEligibleForHidingSectionVisibility(key) && (
                                                <div>
                                                    <p>{oneid.linkablePlans.zeroBalanceAccount}</p>
                                                    <p>
                                                        {
                                                            oneid.linkablePlans
                                                                .reinitiateZeroBalanceAccount
                                                        }
                                                    </p>
                                                    <div>
                                                        <button
                                                            className="btn btn-primary btn-primary--outline"
                                                            onClick={() => verifyAccount(key)}
                                                        >
                                                            {oneid.linkablePlans.keepThisAccount}
                                                        </button>
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={() => hideAccount(key)}
                                                        >
                                                            {oneid.linkablePlans.hideThisAccount}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        <div className="row cta investment-button-row">
                            <button
                                id="submitButton"
                                type="submit"
                                className="col-12 btn btn-primary oneid-button-large"
                                disabled={!checkForEnabling()}
                                onClick={continueClick}
                            >
                                {oneid.linkablePlans.continue}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};

export default LinkablePlans;
