import type { Dispatch } from "react";
import FingerprintUtils from "../../../../../utils/FingerprintUtils.js";
import { callAuthenticateToken } from "../../../../services/authentication/AuthenticationService";
import { AuthActionTypes } from "../../useAuthenticateReducer";
import { AuthAction } from "../../useAuthenticateReducer/useAuthenticateReducer";
const { getFingerprint } = FingerprintUtils;

interface AccessPortalGroup {
    accessPortalList: { [key: string]: null | string }[];
    accu: string;
    pptWebAccuCode: null;
}

interface PrimaryPlan {
    accuCode: null;
    database: string;
    defaultGroupId: null;
    defaultIndId: null;
    definedBenefitPlan: boolean;
    gaId: string;
    indId: string;
    planId: null;
    statusCode: string;
    totalBal: number;
}

export interface AuthData {
    accessPortalGroups: AccessPortalGroup[];
    allAccessPortalGroups: AccessPortalGroup[];
    biometricAllowed: boolean;
    destination: null;
    destinationUrl: string;
    integrated: boolean;
    languageCode: string;
    liatCode: null;
    primaryPlan: PrimaryPlan | null;
    state: string;
    userRegId: string;
}

interface FingerPrintResponse {
    accu?: string;
    deviceFingerPrint: string;
    flowName?: string;
    hasLiedBrowser?: boolean;
    hasLiedLanguages?: boolean;
    hasLiedOs?: boolean;
    hasLiedResolution?: boolean;
    idToken?: string;
    language?: string;
    userAgent?: string;
}

interface AuthenticateArgs {
    accuCode: string;
    deeplink: {
        featureName: string;
        gaId?: string;
        params: string;
    };
    dispatch: Dispatch<AuthAction>;
    idToken?: string;
    setLoggingInState: (loading: boolean) => void;
}

const authenticateTokenPcap = async ({
    accuCode,
    deeplink,
    idToken,
    setLoggingInState,
    dispatch
}: AuthenticateArgs): Promise<AuthData> => {
    setLoggingInState(true);
    if (!idToken) {
        setLoggingInState(false);
        dispatch({ type: AuthActionTypes.INVALID_AUTHENTICATION });
        throw new Error("Receiving Empty idToken");
    }

    dispatch({ type: AuthActionTypes.PROCESSING_AUTHENTICATION });

    try {
        const fingerPrintResponse: FingerPrintResponse = await getFingerprint(false);

        let flowName = "mfa";
        if (deeplink) {
            flowName = "deepLink";
        }

        fingerPrintResponse.idToken = idToken;
        fingerPrintResponse.flowName = flowName;
        fingerPrintResponse.accu = accuCode;

        const authResponse: AuthData = await callAuthenticateToken(fingerPrintResponse);
        return authResponse;
    } catch (error) {
        console.error("getFingerprint error", error);
        throw error;
    }
};

export default authenticateTokenPcap;
