import axios from "axios";
import ReferenceData from "../../constants/ReferenceData";
import { executeGet, executePostWithAuth, executePostWithPcapAuth } from "../../utils/appUtils";
import { handleFault } from "../../utils/errorUtils";
import localBuildConfig from "../../../../../../build/buildConfig.json";

export const multiAuth = localBuildConfig.multiAuth;
export const currentPcBaseApiUrl = multiAuth[window.subdomain];

export const getUsername = () => {
    const getUsernameDataSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl = __baseApiUrl__ + ReferenceData.GET_USERNAME_API;

    return executeGet(liveUrl, "getUsernameDataDefault")
        .then(getUsernameDataSuccess)
        .catch((err) => {
            console.log("getUsernameData Error: ", err);
            throw err;
        });
};

export const resetPassword = (payload) => {
    const resetPasswordSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl = __baseApiUrl__ + ReferenceData.RESET_ACCOUNT_API;

    return executePostWithAuth(liveUrl, "resetPasswordDefault", payload)
        .then(resetPasswordSuccess)
        .catch((err) => {
            console.log("resetPassword Error: ", err);
            throw err;
        });
};
export const resetPasswordFreemiumPcap = (payload) => {
    const resetPasswordSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl = currentPcBaseApiUrl + ReferenceData.RESET_PASSWORD_FREEMIUM_PCAP;

    return executePostWithPcapAuth(liveUrl, "resetPasswordFreemium", payload)
        .then(resetPasswordSuccess)
        .catch((err) => {
            console.log("resetPasswordFreemium Error: ", err);
            throw err;
        });
};
export const authenticateResetPasswordTokenFreemium = (payload) => {
    const resetPasswordSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl = currentPcBaseApiUrl + ReferenceData.AUTHENTICATE_RESET_PASSWORD_TOKEN;

    return executePostWithAuth(liveUrl, "resetPasswordDefault", payload)
        .then(resetPasswordSuccess)
        .catch((err) => {
            console.log("resetPassword Error: ", err);
            throw err;
        });
};

export const callChallengeResetPasswordFreemium = (emailAddr, accu) => {
    const challengeResetPasswordFreemiumSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl = currentPcBaseApiUrl + ReferenceData.CHALLENGE_RESET_PASSWORD_FREEMIUM;
    const formData = new FormData();

    formData.append("username", emailAddr);
    formData.append("apiClient", "WEB");

    if (
        String(accu).toLowerCase() !== "empower" &&
        String(accu).toLowerCase() !== "free" &&
        String(accu).toLowerCase() !== "myerira"
    ) {
        axios.defaults.headers.common["Authorization"] = "";
        axios.defaults.headers.common["Idproofenabled"] = true;
    }

    return axios({
        method: ReferenceData.HTTP_METHOD.POST,
        url: liveUrl,
        data: formData,
        headers: { "content-type": ReferenceData.HTTP_CONTENT_TYPE.URL_ENCODED },
        withCredentials: true
    })
        .then(challengeResetPasswordFreemiumSuccess)
        .catch((err) => {
            console.log("challengeResetPasswordFreemium Error: ", err);
            handleFault(err.response, "verifyEmailFault");
            return err;
        });
};
