type AccuCustomization = {
    isLoginPart: boolean;
};

/**
 * Determines whether the multi-authentication flag is enabled based on the provided
 * customization settings, current path, and query parameters in the URL.
 *
 * @param {AccuCustomization} params.accuCustomization - The customization settings.
 * @param {string} params.currentPath - The current path of the application.
 * @returns {boolean} - Returns `true` if the multi-authentication flag is enabled, otherwise `false`.
 *
 */
const multiAuthFlagEnabled = ({
    accuCustomization,
    currentPath
}: {
    accuCustomization: AccuCustomization;
    currentPath: string;
}): boolean => {
    // isLoginPart is primarily used for development and testing purposes with plans of being removed once SFD is live in production.
    // This page will not be dependent on the ACCU flag.
    const isLoginPartQueryParamPresent =
        String(window.location.href).indexOf("isLoginPart=true") > -1;

    // If we have special url/flag set in ui-style-guide as loginPart call authenticatePart else go to existing authenticate/authenticateLite flow.
    // An Amplitude/ui-style-guide feature flag driven page /sfd-login should always use the multiauth API.
    const multiAuthFlagsEnabled =
        accuCustomization.isLoginPart && currentPath.includes("/sfd-login");

    return multiAuthFlagsEnabled || isLoginPartQueryParamPresent;
};

export default multiAuthFlagEnabled;
