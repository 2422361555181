/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import accountRecoveryEvents from "../../events/AccountRecoveryEvents";

import { useTranslations } from "../../queries";
import { getUsername } from "../../services/accountRecovery/AccountRecoveryService";
/**
 * Angular to react version
 * @param {*} param0
 * @returns
 */

interface AccountRecoveryConfirmationTranslations {
    confirmationContent: string;
    confirmationHeader: string;
    confirmationLink: string;
    loginHelp: string;
    username: string;
}

const AccountRecoveryConfirmationUpd = () => {
    const { confirmationContent, confirmationHeader, confirmationLink, loginHelp, username } =
        useTranslations<AccountRecoveryConfirmationTranslations>();

    const [userInfo, setUserInfo] = useState({
        username: "",
        password: "",
        confirmPassword: "",
        userNames: []
    });

    const confirmClick = (e) => {
        e.preventDefault();
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_LINK,
            selection: accountRecoveryEvents.CONFIRM_CLICK
        });
        window.location.href = "/participant/#/login";
    };

    const getUserNameInfo = async () => {
        const data = await getUsername();
        if (data !== null && data.status == "successful" && data.username !== null) {
            setUserInfo(data);
        }
    };

    useEffect(() => {
        getUserNameInfo();
    });

    const showBlockingView = false; // TBD
    const isBlockingLoaded = true; // TBD

    const blockingClass = !showBlockingView ? "with-top-marging" : "";
    const isBlockingLoadedClass = !isBlockingLoaded ? "hide" : "";

    return (
        <div
            id="mainBody"
            className={`container with-breadcrumb with-top-margin ${blockingClass} ${isBlockingLoadedClass}`}
        >
            <div className="container row">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
                        <header className="contained-form-header">
                            <h1>{loginHelp}</h1>
                        </header>
                        <div className="inner-container with-padding with-shadow">
                            <form
                                id="accountRecoveryConfirmForm"
                                name="accountRecoveryConfirmForm"
                                noValidate
                                autoComplete="off"
                            >
                                <div className="form-group">
                                    <label htmlFor="username">{username}</label>
                                    <input
                                        type="text"
                                        className="form-control form-disabled"
                                        id="username"
                                        name="username"
                                        value={userInfo?.username}
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <div>
                                        <strong>{confirmationHeader}</strong>
                                    </div>
                                    <div>{confirmationContent}</div>
                                    <br />
                                    <p>
                                        <a href="home" onClick={(e) => confirmClick(e)}>
                                            {confirmationLink}
                                        </a>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountRecoveryConfirmationUpd;
