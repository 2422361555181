import type { AxiosResponse } from "axios";
import { executeGet } from "../../../utils/appUtils";
import StringUtil from "core-ui/client/src/app/core/util/StringUtil";
import ReferenceData from "../../../constants/ReferenceData";

interface GroupTxnAccessParams {
    groupId: string | undefined;
    individualId: string | undefined;
    txnCode: string;
}

const groupTxnAccess = async (params: GroupTxnAccessParams): Promise<AxiosResponse> => {
    const liveUrl = StringUtil.supplant(ReferenceData.GROUP_TXN_ACCESS, {
        individualId: params.individualId,
        groupId: params.groupId,
        txnCode: params.txnCode
    });

    return executeGet(liveUrl, "groupTxnAccessFault");
};

export default groupTxnAccess;
