import {
    updateAccuCookie,
    deleteCookie,
    resetAccuService,
    getPreLoginAccuRetrieval
} from "../../../../services/accuCodeService";
import { getDefaultCustomizationRetr } from "../../../../services/authentication/AuthenticationService";

const authResetFlow = async (newAccuCode) => {
    let preLoginData;
    let defaultData;
    let resetAccuData;

    try {
        resetAccuData = await resetAccuService(newAccuCode);

        //set cookie
        updateAccuCookie(newAccuCode);
        deleteCookie("PM-ACCU-TOKEN", "/", null);
    } catch (error) {
        console.error("ERROR - with resetAccuService: ", error);
    }

    if (resetAccuData) {
        try {
            defaultData = await getDefaultCustomizationRetr();
        } catch (error) {
            console.error("ERROR - with getDefaultCustomizationRetr: ", error);
        }
    }

    if (defaultData) {
        try {
            preLoginData = await getPreLoginAccuRetrieval(newAccuCode);

            window.accuCustomization = { ...defaultData, ...preLoginData };
        } catch (error) {
            console.error("ERROR - with getPreLoginAccuRetrieval: ", error);
        }
    }

    if (preLoginData) {
        try {
            updateAccuCookie(newAccuCode);
        } catch (error) {
            console.error("ERROR - with updateAccuCookie: ", error);
        }
    }
};

export default authResetFlow;
