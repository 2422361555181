import { useState, useEffect } from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import AccessibilityUtil from "core-ui/client/src/app/core/util/AccessibilityUtil";

import accountRecoveryEvents from "../../events/AccountRecoveryEvents";
import { validatePassword } from "../../utils/formUtils";
import { getUsername, resetPassword } from "../../services/accountRecovery/AccountRecoveryService";
import { useTranslations } from "../../queries";

const useAccountRecovery = ({ mutltipleUserFlow, redirect }) => {
    const translations = useTranslations();
    const [passwordReset, setPasswordReset] = useState(false);
    const [wasFocus, setWasFocus] = useState(false);
    const [userInfo, setUserInfo] = useState({
        username: "",
        password: "",
        confirmPassword: "",
        userNames: [""]
    });
    const [errorsBag, setErrorsBag] = useState({});
    const [showSpinner, setShowSpinner] = useState(false);
    const [currentWorkingUserName, setCurrentWorkingUserName] = useState("");
    const [resetStatus, setResetStatus] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [pwdChangedUserName, setPwdChangedUserName] = useState("");
    const [formValidations, setFormValidations] = useState({
        isValid: false,
        errorsLength: 0,
        isSubmited: false
    });

    const returnClick = () => {
        window.location.href = "/participant/#/login";
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: accountRecoveryEvents.RETURN_CLICK
        });
    };

    const passwordHelpClick = (userName = "") => {
        setPasswordReset(!passwordReset);
        if (mutltipleUserFlow) {
            setCurrentWorkingUserName(userName);
        }
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_LINK,
            selection: accountRecoveryEvents.PASSWORD_RESET,
            payload: {
                passwordReset
            }
        });
    };

    const cancelPassword = () => {
        setPasswordReset(false);
        setCurrentWorkingUserName("");
    };

    const updateFormInput = (label, value) => {
        const info = { ...userInfo };
        info[label] = value;
        setUserInfo(info);
        validateErrors(label, value);
    };

    const validateErrors = (label, value) => {
        if (label === "password") {
            errorsBag[label] = validatePassword(value);
            errorsBag[label].notMatchUsername = userInfo.password !== userInfo.username;
        }
        if (label === "confirmPassword") {
            errorsBag[label] = {
                required: value.length > 0,
                match: userInfo.password === value
            };
        }
        errorsBag[label].isValid = isValidValue(errorsBag[label]);
        setErrorsBag(errorsBag);
    };

    const isValidValue = (errorBag) => {
        for (const key in errorBag) {
            if (errorBag[key] === false && key !== "isValid") {
                return false;
            }
        }
        return true;
    };

    const loadUsername = async () => {
        const data = await getUsername();
        if (data !== null && data.status == "successful" && data.username !== null) {
            setUserInfo(data);
        }
    };

    const getFormValidations = () => {
        let errorsLength = 0;
        for (const key in errorsBag) {
            if (!errorsBag[key].isValid) {
                errorsLength++;
            }
        }
        const validations = {
            isSubmited: true,
            errorsLength,
            isValid: errorsLength === 0
        };

        setFormValidations(validations);
        return validations;
    };

    const submitForm = async (event) => {
        event.preventDefault();
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_LINK,
            selection: accountRecoveryEvents.CONFIRM_CLICK
        });
        const { isValid } = getFormValidations();
        const displayErrorMsg = translations?.canNotResetAccount;

        // Check if the form input is valid
        if (isValid) {
            const resetQueryData = {
                userName: mutltipleUserFlow ? currentWorkingUserName : userInfo.username,
                password: userInfo.password,
                confirmPassword: userInfo.confirmPassword
            };
            setShowSpinner(true);

            try {
                const data = await resetPassword(resetQueryData);
                if (data !== null && data.status === "successful") {
                    setShowSpinner(false);
                    if (!mutltipleUserFlow) {
                        redirect({ state: "accountRecoveryConfirm" }, "ALL");
                    } else {
                        setPasswordReset(false);
                        setPwdChangedUserName(currentWorkingUserName);
                    }
                } else {
                    setResetStatus(displayErrorMsg);
                }
            } catch (error) {
                setShowSpinner(false);
                if (error.headers?.exception || error.headers.exceptionMessage) {
                    setResetStatus(displayErrorMsg);
                    if (error.data.error.errors) {
                        setResetStatus(displayErrorMsg);
                    }
                    dispatchAmplitude({
                        eventType: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                        selection: accountRecoveryEvents.LOGIN_ERROR_CODE,
                        payload: { error_code: error.data.error?.code }
                    });
                } else {
                    if (error.data?.data?.password) {
                        setPasswordError(error.data.data.password);
                    }
                    if (error.data?.data?.confirmPassword) {
                        setConfirmPasswordError(error.data.data.confirmPassword);
                    }
                    setResetStatus(displayErrorMsg);
                }
            }
        } else {
            setShowSpinner(false);
            validateErrors("password", userInfo.password);
            validateErrors("confirmPassword", userInfo.confirmPassword);
            //set the focus on the screen reader error block to prompt the participant to fix the issue
            AccessibilityUtil.focusElement("screenReader");
        }
    };

    useEffect(() => {
        loadUsername();
    }, []);

    return {
        translations,
        passwordReset,
        userInfo,
        errorsBag,
        showSpinner,
        currentWorkingUserName,
        resetStatus,
        confirmPasswordError,
        passwordError,
        formValidations,
        pwdChangedUserName,
        returnClick,
        submitForm,
        updateFormInput,
        passwordHelpClick,
        cancelPassword,
        setWasFocus,
        wasFocus
    };
};

export default useAccountRecovery;
