const investmentInformationFormViewTemplate = `
<form role="form" name="investmentInformationForm" data-ng-submit="ctrl.submitForm()" novalidate>
    <div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2" ng-if="!ctrl.loading">
        <header class="contained-form-header">
            <h2>{{::ctrl.content.title}}</h2>
            <p id="investmentInformationDescription">{{::ctrl.content.subtitle}}</p>
        </header>

        <div class="inner-container with-padding with-shadow">            

            <div class="form-group" data-ng-class="ctrl.getFormGroupClass('groupId');">
                <label for="groupIdInput" class="control-label">{{::ctrl.content.labelGroupId}}</label>
                <input type="text"
                       class="form-control"
                       id="groupIdInput"
                       name="groupId"
                       inputmode="numeric"
                       auto-focus
                       data-ng-required="true"
                       data-ng-pattern="/^[0-9]+-[a-zA-Z0-9]+$/"
                       data-ng-model="ctrl.groupId"
                       data-ng-model-options="{ updateOn: 'blur', allowInvalid: true }"
                       aria-describedby="investmentInformationDescription" />

                <ng-messages for="ctrl.getFieldError('groupId');" 
                             ng-if="ctrl.displayFieldError('groupId');" 
                             class="form-group has-error"
                             aria-live="assertive">
                    <ng-message when="required">{{::ctrl.content.errorGroupIdRequired}}</ng-message>
                    <ng-message when="pattern">{{::ctrl.content.errorGroupIdInvalid}}</ng-message>
                </ng-messages>
            </div>

		    <button type="submit"
                    class="btn btn-primary btn-lg btn-block margin-top-default"
                    ng-click="handleFundInformationButtonClicked()"
                    >
					{{::ctrl.content.buttonContinue}}
            </button>
        </div>
    </div>
</form>
`;
export default investmentInformationFormViewTemplate;
