import ReferenceData from "../../../../constants/ReferenceData";

import getEmpowerRestrictedPlanAccuCode from "../getEmpowerRestrictedPlanAccuCode";
import { AuthActionTypes } from "../../useAuthenticateReducer";

import { AuthData } from "./authenticate";
import { MFA_ACTIVATION_CODE_DELIVERY_OPTIONS } from "../../../../routes";
import { Dispatch } from "react";
import { AuthAction } from "../../useAuthenticateReducer/useAuthenticateReducer";
import authResetFlow from "./authResetFlow";

interface AuthenticateSuccessArgs {
    accuCode: string;
    authData: AuthData;
    dispatch: Dispatch<AuthAction>;
    setAccuCode: (accu: string) => void;
    setLoggingInState: (loading: boolean) => void;
}

const onAuthenticateSuccess = async ({
    accuCode,
    authData,
    dispatch,
    setLoggingInState,
    setAccuCode
}: AuthenticateSuccessArgs) => {
    dispatch({
        type: AuthActionTypes.LOGIN_SUCCESS,
        payload: {
            userId: authData.userRegId,
            flowName: "mfa",
            errorMessage: ""
        }
    });

    // After successful authentication, toggle logginIn loading flag back to false
    setLoggingInState(false);

    if (accuCode.toLowerCase() === "empower") {
        const newAccuCode = getEmpowerRestrictedPlanAccuCode(authData);

        if (
            newAccuCode &&
            accuCode !== newAccuCode &&
            ReferenceData.supportedACCU.indexOf(newAccuCode) >= 0
        ) {
            window.accu = newAccuCode;
            setAccuCode(newAccuCode);

            await authResetFlow(newAccuCode);
        }
    }

    return "/participant/#/" + MFA_ACTIVATION_CODE_DELIVERY_OPTIONS;
};

export default onAuthenticateSuccess;
