import { Experiment } from "@amplitude/experiment-js-client";

type AmplitudeEnvType = "Production" | "uat" | "localhost";

const AMPLITUDE_EXPERIMENTS_PROD_KEY = "client-EhRAd4skYsw9tgfTh2QUuWVP66j0uDOz";
const AMPLITUDE_EXPERIMENTS_DEV_KEY = "client-0pDrz5x3DtKUFsT65cme2JKZF52bLCDz";

/**
 * Initializes the experiment client with Amplitude Analytics.
 * We will set the ACCU user property on the loginAppStart event.
 *
 * @param {AmplitudeEnvType} amplitudeEnv - The environment to initialize the experiment client with.
 */
const initializeWithAmplitudeAnalytics = (amplitudeEnv: AmplitudeEnvType) => {
    // keys for new amplitude events
    const apiKey =
        amplitudeEnv === "Production"
            ? AMPLITUDE_EXPERIMENTS_PROD_KEY
            : AMPLITUDE_EXPERIMENTS_DEV_KEY;

    const experiment = Experiment.initializeWithAmplitudeAnalytics(apiKey);

    // Store the experiments client on the global event bus object
    globalThis.empower.eventBus.Experiment = experiment;
};

export default initializeWithAmplitudeAnalytics;
