import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import { retrievePlansAvailableForPrimaryPlan } from "../../services/oneId/primaryPlanService";

const useAvailPlansForPrimaryPlan = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_AVAIL_PLANS_FOR_PRIMARY_PLAN],
        queryFn: async () => {
            const { data } = await retrievePlansAvailableForPrimaryPlan();
            return data || [];
        }
    });
};

export default useAvailPlansForPrimaryPlan;
