export const routeDestination = {
    retailUnregistered: "/participant/#/register?accu=MYERIRA",
    retailRegistered: "/participant/#/login?accu=MYERIRA"
};

export const detailsForm = [
    { info: "firstName", label: "First Name", size: "col-xs-12" },
    { info: "lastName", label: "Last Name", size: "col-xs-12" },
    { info: "addressLine1", label: "Address Line 1", size: "col-xs-12" },
    { info: "addressLine2", label: "Address Line 2", size: "col-xs-12" },
    { info: "city", label: "City", message: "City is required", size: "col-xs-12" },
    { info: "state", label: "State", defaultValue: "Select...", size: "col-xs-7" },
    { info: "zipCode", label: "Zip Code", size: "col-xs-5" }
];

export const personalDetailsForm = [
    {
        info: "ssn",
        label: "Social Security Number",
        size: "col-xs-12",
        placeholder: "XXXX-XX-XXXX"
    },
    { info: "dateOfBirth", label: "Date of Birth MM/DD/YYYY", size: "col-xs-12" }
];

export const fieldValidations = {
    firstName: {
        required: true,
        maxLength: 20,
        pattern: true,
        inputType: "text"
    },
    lastName: {
        required: true,
        maxLength: 35,
        pattern: true,
        inputType: "text"
    },
    ssn: {
        required: true,
        maxLengthMsg: 9,
        maxLength: 11,
        pattern: true,
        inputType: "ssn"
    },
    dateOfBirth: {
        required: true,
        pattern: true,
        inputType: "dateOfBirth"
    },
    addressLine1: {
        required: true,
        maxLength: 35,
        pattern: true,
        inputType: "text"
    },
    addressLine2: {
        required: false,
        maxLength: 35,
        pattern: true,
        inputType: "text"
    },
    city: {
        required: true,
        maxLength: 20,
        pattern: true,
        inputType: "text"
    },
    state: {
        required: true,
        pattern: false,
        inputType: "select"
    },
    zipCode: {
        required: true,
        pattern: true,
        inputType: "zip"
    }
};
