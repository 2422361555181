import React, { useEffect, useState, useCallback } from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { useForm } from "react-hook-form";
import { useTranslations } from "../../queries";
import useRedirect from "../../hooks/useRedirect";
import useAvailPlansForPrimaryPlan from "../../queries/useLinkablePlans/useAvailPlansForPrimaryPlan";
import { getGroupTxnAccess } from "../../services/sharedService";
import eventBus from "../../../utils/setEventBus";
import {
    ChoosePrimaryPlanFields,
    IPlansMapWithIndid,
    ChoosePrimaryPlansTranslations
} from "./types";
import oneIdEvents from "./events/OneIdEvents";
import useChooseDefaultPlanAndRefresh from "../../queries/useLinkablePlans/useChooseDefaultPlanAndRefesh";
import {
    resetAccuService,
    updateAccuCookie,
    deleteCookie,
    getPreLoginAccuRetrieval
} from "../../services/accuCodeService";
import { getDefaultCustomizationRetr } from "../../services/authentication/AuthenticationService";
import ReferenceData from "../../constants/ReferenceData";

interface ChoosePrimaryPlanProps {
    oneIdFlow: string;
    redirectService: {
        redirect: (data: { destinationUrl?: string; state: string }, key: string) => void;
    };
}

const DEFAULT_VALUES = {
    selectedOption: "",
    selectedValue: ""
};

// The header and intro text of the choose primary plan page are determined at runtime by the oneIdFlow param
// The rest of the text on that page are unchanged between different flows
// The value can be "login" or "linking"
// The "login" flow uses the default header and intro, whereas "linking" uses alternate texts that are TBD.
// oneIdFlow: {
//    value: "",
//    squash: true
// }
const ChoosePrimaryPlan = ({ redirectService, oneIdFlow }: ChoosePrimaryPlanProps) => {
    const [loading, setLoading] = useState(false);
    const [showBeAware, setShowBeAware] = useState(false);
    const [primaryPlanMapWithInIndid, setPrimaryPlanMapWithInIndid] = useState<any>(null);
    const [primaryPlanChoice, setPrimaryPlanChoice] = useState("");
    const [dataLoaded, setDataLoaded] = useState(false);
    const [indId, setIndId] = useState("");
    const [dbName, setDbName] = useState("");
    const [accuCode, setAccuCode] = useState("");
    const initialValues = DEFAULT_VALUES;

    const { choosePrimaryPlan, choosePrimaryPlanLinking } =
        useTranslations<ChoosePrimaryPlansTranslations>();

    const {
        data: availablePlansData,
        error: availablePlansError,
        isLoading: availablePlansLoading
    } = useAvailPlansForPrimaryPlan();

    const {
        data: defaultPlanData,
        error: defaultPlanError,
        isLoading: defaultPlanLoading
    } = useChooseDefaultPlanAndRefresh(indId, dbName, accuCode);

    let redirect: any = useRedirect();
    if (redirectService) {
        redirect = redirectService.redirect;
    }

    const {
        handleSubmit,
        register,
        setValue,
        watch,
        setFocus,
        formState: { isValid }
    } = useForm<ChoosePrimaryPlanFields>({
        values: initialValues
    });

    // eslint-disable-next-line
    const { selectedOption, selectedValue } = watch();

    // Values : 'linking', ''
    const flow = oneIdFlow;
    let header = choosePrimaryPlan.header;
    let intro = choosePrimaryPlan.intro;

    if (flow === "linking") {
        header = choosePrimaryPlanLinking.header;
        intro = choosePrimaryPlanLinking.intro;
    }

    useEffect(() => {
        setFocus("selectedOption");
    }, [setFocus]);

    // eslint-disable-next-line react-hooks-extra/no-unnecessary-use-callback
    const dispatchAmplitudeEvent = useCallback((event: React.MouseEvent) => {
        const payload = String(event.currentTarget.id).toLowerCase();
        eventBus.dispatch(AMPLITUDE_EVENTS.SELECT_BUTTON, event.target, payload);

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: payload,
            payload: {
                payload
            }
        });
    }, []);

    /**
     * Add handle radio tab for accessibility to be checked when user tabs to next component
     * @param e
     */
    const handleRadioTab = (e: React.KeyboardEvent) => {
        if (e["key"] === "Tab" && e.currentTarget) {
            //   setSelectedValue(e.currentTarget.id);
            setValue("selectedOption", e.currentTarget["id"]);
            setValue("selectedValue", e.currentTarget["id"], { shouldValidate: true });
        }
    };

    const handleRadioToggle = (e: React.MouseEvent) => {
        const payload = String(e.currentTarget.id).toLowerCase();
        eventBus.dispatch(AMPLITUDE_EVENTS.SELECT_BUTTON, e.target, payload);

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: payload,
            payload: {
                payload
            }
        });

        setPrimaryPlanChoice(e.currentTarget.id);
        setValue("selectedOption", e.currentTarget.id);
        setValue("selectedValue", e.currentTarget.id, { shouldValidate: true });
    };

    const continueClick = async () => {
        eventBus.dispatch(oneIdEvents.CHOOSE_PRIMARY_PLAN_CONTD_CLICKED, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: oneIdEvents.CHOOSE_PRIMARY_PLAN_CONTD_CLICKED
            }
        });

        const temp = String(primaryPlanChoice).split(":");
        setIndId(temp[0]);
        setDbName(temp[1]);
        setAccuCode(temp[2]);
    };

    const continueServiceCalls = async (defaultPlan) => {
        if (window.accu !== defaultPlanData.data.primaryPlan.accuCode) {
            const newAccuCode = defaultPlanData.data.primaryPlan.accuCode;

            let preLoginData;
            let defaultData;
            let resetAccuData;

            if (
                newAccuCode &&
                window.accu !== newAccuCode &&
                ReferenceData.supportedACCU.indexOf(newAccuCode) >= 0
            ) {
                window.accu = newAccuCode;
                // eslint-disable-next-line no-undef
                globalThis.css.accu = newAccuCode;

                //update tomcat
                try {
                    resetAccuData = await resetAccuService(newAccuCode);

                    //set cookie
                    updateAccuCookie(newAccuCode);
                    deleteCookie("PM-ACCU-TOKEN", "/", null);
                } catch (error) {
                    console.error("ERROR - with resetAccuService: ", error);
                }

                if (resetAccuData) {
                    try {
                        defaultData = await getDefaultCustomizationRetr();
                    } catch (error) {
                        console.error("ERROR - with getDefaultCustomizationRetr: ", error);
                    }
                }

                if (defaultData) {
                    try {
                        preLoginData = await getPreLoginAccuRetrieval(newAccuCode);

                        window.accuCustomization = { ...defaultData, ...preLoginData };
                    } catch (error) {
                        console.error("ERROR - with getPreLoginAccuRetrieval: ", error);
                    }
                }

                if (preLoginData) {
                    try {
                        updateAccuCookie(newAccuCode);
                    } catch (error) {
                        console.error("ERROR - with updateAccuCookie: ", error);
                    }
                }

                redirect(defaultPlan.data, "ALL");
            } else {
                redirect(defaultPlan.data, "ALL");
            }
        } else {
            redirect(defaultPlan.data, "ALL");
        }
    };

    if (defaultPlanData && !defaultPlanError && !defaultPlanLoading && isValid) {
        continueServiceCalls(defaultPlanData);
    }

    const setup = async (item: any) => {
        const txnCodeParamWithGroup = {
            txnCode: "WNOAIN",
            individualId: item.indId,
            groupId: item.gaId
        };

        setLoading(true);
        const results = await getGroupTxnAccess(txnCodeParamWithGroup);

        if (results.data.WNOAIN === "true") {
            setShowBeAware(true);
        }

        setLoading(false);
    };

    if (availablePlansData && !availablePlansLoading && !availablePlansError && !dataLoaded) {
        setPrimaryPlanMapWithInIndid(availablePlansData.primaryPlanMapWithInIndid);

        for (const [_, item] of Object.entries(availablePlansData.primaryPlanMapWithInIndid)) {
            setup(item);
        }
        setDataLoaded(true);
    }

    const renderPrimaryPlanMapWithInIndid = (planList) => {
        return Object.entries(planList).map(([key, value]) => {
            const planValue = value as IPlansMapWithIndid;
            const displayValue =
                planValue.indId + ":" + planValue.dbname + ":" + planValue.pptwebAccuCode;

            return (
                <div className="panel panel-default" key={key}>
                    <header className="panel-heading form-group inactive" id={key}>
                        <label className="radio-inline panel-title">
                            <input
                                id={key}
                                type="radio"
                                value={displayValue}
                                aria-label={planValue.planName}
                                aria-checked={selectedValue === key}
                                onKeyDown={handleRadioTab}
                                onClick={handleRadioToggle}
                                {...register(`selectedOption`, { required: true })}
                            />
                            <span style={{ whiteSpace: "normal" }}>{planValue.planName}</span>
                        </label>
                    </header>
                </div>
            );
        });
    };

    return (
        <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 account-selection">
            <header className="contained-form-header margin-top-default">
                <h1>{header} Ts</h1>
                <p> {intro} </p>
            </header>
            {loading ||
                defaultPlanLoading ||
                (availablePlansLoading && (
                    <div id="statementsDocs-spinner" className="loaderBackground just-element">
                        <div className="loader"></div>
                    </div>
                ))}
            <form
                className="with-padding with-top-margin margin-bottom-200"
                data-testid="choose-primary-plan-form"
                name="primaryPlanSelectionForm"
                onSubmit={handleSubmit(continueClick)}
            >
                <div className="inner-container with-padding with-shadow clearfix">
                    {primaryPlanMapWithInIndid && (
                        <div className="form-group panel-group">
                            <label className="control-label">{choosePrimaryPlan.label}</label>
                            {renderPrimaryPlanMapWithInIndid(primaryPlanMapWithInIndid)}
                        </div>
                    )}

                    <div className="row cta investment-button-row margin-bottom-200">
                        <button
                            id="submitButton"
                            type="submit"
                            className="btn btn-primary btn-block btn-lg margin-top-default"
                            disabled={!primaryPlanChoice}
                            onClick={dispatchAmplitudeEvent}
                        >
                            {choosePrimaryPlan.continuebutton}
                        </button>
                    </div>

                    {showBeAware && (
                        <div className="form-group panel-group">
                            <label className="control-label">
                                {choosePrimaryPlan.beAwareheader}
                            </label>
                            <div className="panel panel-default padding-100">
                                {choosePrimaryPlan.beAwareContent}
                            </div>
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
};

export default ChoosePrimaryPlan;
