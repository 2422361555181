import React, { useEffect, useState, useCallback } from "react";
import { useAuthData } from "../../queries";
import { useQuery } from "@tanstack/react-query";
import { getCustomization } from "../../services/deepLink/deepLinkService";
import { QUERY_KEYS } from "../../queries/constants";
import { useNavigate } from "react-router-dom";
import stateMap from "../../services/redirect/StateMap";
import handleDeepLinkAuth from "./helpers/handleDeepLinkAuth";
import { deleteCookie } from "../../services/accuCodeService";
import useRedirect from "../../hooks/useRedirect";
import { Loader } from "gw-shared-components";

const DeepLinkContainer = () => {
    const key = globalThis.deepLinkCust ? "RECALL" : "";
    const [deepLinkErrorCode, setDeepLinkErrorCode] = useState(null);

    const { data: authData, isError: authDataErr } = useAuthData(key);

    const { data: customizationData, isError: customizationErr } = useQuery({
        queryKey: [QUERY_KEYS.GET_CUSTOMIZATION, authData?.authenticated],
        queryFn: () =>
            getCustomization(authData?.authenticated, globalThis.deepLinkParams.featureName),
        enabled: !!authData
    });

    const navigate = useNavigate();
    const redirect = useRedirect();

    // eslint-disable-next-line react-hooks-extra/no-unnecessary-use-callback
    const deleteDataLoggingOut = useCallback(() => {
        deleteCookie("JSESSIONID", "/participant-web-services", null);
        deleteCookie("JSESSIONID", "/participant-authentication", null);
        deleteCookie("site_tok", "/participant", null);
        deleteCookie("site_tok", "/", null);
        deleteCookie("X-CSRF-TOKEN", "/", null);
        deleteCookie("PM-INDID-TOKEN", "/", null);
        deleteCookie("PM-PLAN-TOKEN", "/", null);
        sessionStorage.removeItem("currentMenuState");
        sessionStorage.removeItem("activeNavItems");
        sessionStorage.removeItem("activeMenuRefOnLastState");
    }, []);

    const navToLogin = useCallback(
        (deleteData) => {
            const loginUrl = stateMap.get("login");

            if (deleteData) {
                deleteDataLoggingOut();
            }

            if (loginUrl) {
                navigate(loginUrl, {});
            }
        },
        [navigate, deleteDataLoggingOut]
    );

    const navToRegister = useCallback(
        (deleteData) => {
            const registerUrl = stateMap.get("register");

            if (deleteData) {
                deleteDataLoggingOut();
            }

            if (registerUrl) {
                navigate(registerUrl, {});
            }
        },
        [navigate, deleteDataLoggingOut]
    );

    useEffect(() => {
        if (customizationData && authData) {
            globalThis.deepLinkCust = customizationData;

            if (authData.authenticated) {
                if (!authData.registered) {
                    navToRegister(false);
                } else {
                    try {
                        handleDeepLinkAuth(
                            customizationData,
                            authData,
                            navToLogin,
                            navToRegister,
                            redirect
                        );
                    } catch (e) {
                        console.error(e);
                        // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
                        setDeepLinkErrorCode(customizationData.errors.code);
                    }
                }
            } else {
                navToLogin(false);
            }
        }
    }, [customizationData, authData, navToLogin, navToRegister, redirect]);

    if (!deepLinkErrorCode && !authDataErr && !customizationErr) {
        return <Loader fullscreen={true} messages={null} showMessages={false} useSvg={false} />;
    }

    return (
        <React.Fragment>
            <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                <header className="contained-form-header margin-top-default">
                    <h1>{"error.headingTitle"}</h1>
                </header>
            </div>
            <div className="inner-container with-padding with-shadow">
                <p>{deepLinkErrorCode}</p>
            </div>
        </React.Fragment>
    );
};

export default DeepLinkContainer;
